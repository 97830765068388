var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('headerBox',{attrs:{"bgColor":"#fff"}},[_vm._v("打卡记录")]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"content-box"},[_c('div',{staticClass:"content-box-header"},[_c('div',[_vm._v("本月正常出勤 "+_vm._s(_vm.normalDay)+" 天")]),_c('div',[_c('van-icon',{staticClass:"arrow-left",attrs:{"name":"arrow-left"},on:{"click":_vm.up}}),_vm._v("   "),_c('span',[_vm._v(_vm._s(_vm.query.year + "年" + _vm.query.month + "月"))]),_vm._v("   "),_c('van-icon',{style:({
                            color: _vm.isNext ? '#999' : '',
                        }),attrs:{"name":"arrow"},on:{"click":_vm.down}})],1)]),_c('div',{staticClass:"content-box-calendar"},[_vm._m(0),_c('div',{staticClass:"content-box-calendar-all"},[(!_vm.loading)?_c('div',{staticClass:"content-box-calendar-content"},[_vm._l((_vm.emptyNum),function(item,index){return _c('div',{key:index + 'x',staticClass:"week"})}),_vm._l((_vm.punchData),function(item,index){return _c('div',{key:index,staticClass:"week"},[_c('div',{class:[
                                    'box',
                                    _vm.getTime(item.date) > _vm.getTime()
                                        ? 'black'
                                        : 'not-clock',
                                    item.clock_status === 1 ? 'clock' : '',
                                    _vm.NotPunch.includes(item.week)
                                        ? 'gray'
                                        : '',
                                    _vm.activeDay === index + 1 ? 'days' : '' ],on:{"click":function($event){return _vm.optionDate(index + 1, item)}}},[_vm._v(" "+_vm._s(index + 1)+" ")])])})],2):_c('div',{staticClass:"loading"},[_c('van-loading',{attrs:{"type":"spinner"}})],1)])]),_c('van-steps',{attrs:{"direction":"vertical","active-icon":"circle","inactive-icon":"circle","active":9999}},_vm._l((_vm.currentDay),function(item){return _c('van-step',{key:item.id},[_c('div',{staticClass:"step-line"},[_c('div',[_vm._v(" "+_vm._s(item.type_name)+" "+_vm._s(item.start_time)+"~"+_vm._s(item.end_time)+" ")]),_c('div',{on:{"click":function($event){return _vm.openImg(item)}}},[(item.is_status === 1)?_c('span',[_vm._v(" "+_vm._s(item.clock_time)+" ")]):_vm._e(),_c('span',{staticClass:"yet"},[(item.is_status === 1)?_c('img',{attrs:{"src":require("@/assets/not-yet.png"),"alt":""}}):_vm._e()]),_vm._v(" "+_vm._s(item.is_status === 0 ? "未打卡" : "已打卡")+" "),(item.is_status === 1)?_c('van-icon',{staticClass:"arrow-right",attrs:{"name":"arrow"}}):_vm._e()],1)])])}),1)],1)])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-box-calendar-head"},[_c('div',{staticClass:"week"},[_vm._v("日")]),_c('div',{staticClass:"week"},[_vm._v("一")]),_c('div',{staticClass:"week"},[_vm._v("二")]),_c('div',{staticClass:"week"},[_vm._v("三")]),_c('div',{staticClass:"week"},[_vm._v("四")]),_c('div',{staticClass:"week"},[_vm._v("五")]),_c('div',{staticClass:"week"},[_vm._v("六")])])}]

export { render, staticRenderFns }