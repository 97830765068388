<template>
    <div class="Video">
        <div>
            <div class="line left"></div>
            <div class="title"></div>
            <div class="line right"></div>
        </div>
        <!-- <div class="header d_flex">
            <div class="logo">
                <img src="@/assets/logo@1x.png" alt="" />
            </div>
            <div class="tit">云宣传</div>
        </div> -->
        <headerBox :backPath="'/index'"></headerBox>
        <div class="title">{{ detail.title }}</div>
        <div class="video-content" v-if="detail.liveInfo">
            <video
                width="980"
                :src="detail.liveInfo.live_url"
                id="video"
                ref="myVideo"
                controls="controls"
                x5-playsinline="true"
                playsinline="true"
                webkit-playsinline="true"
                x-webkit-airplay="true"
                x5-video-orientation="portraint"
                style="object-fit: contain"
                @click="changeStop()"
            ></video>
        </div>
        <div class="progress">
            <div class="current-time">
                {{ currentTimeStr }}
            </div>
            <div class="progress-content">
                <van-progress
                    ref="progress"
                    color="#006FFC"
                    :percentage="curWidth"
                    :show-pivot="false"
                />
            </div>
            <div class="end-time">
                {{ totalTimeStr }}
            </div>
        </div>

        <div class="video-play" v-if="!play" @click="playOperation()">
            <img src="../../assets/video-play.png" />
        </div>

        <van-popup v-model="showSuccess" round :close-on-click-overlay="false">
            <div class="pop-con success">
                <div class="success-title">
                    <img src="../../assets/success-title.png" />
                </div>
                <div class="success-content">
                    <div class="success-icon">
                        <img src="../../assets/success-icon.png" />
                    </div>
                    <div class="success-context">恭喜您，回答正确！</div>
                </div>
                <div class="success-btn" @click="next()">继续观看视频</div>
            </div>
        </van-popup>

        <van-popup v-model="showError" round :close-on-click-overlay="false">
            <div class="pop-con error" v-if="detail.topic">
                <div class="error-title">
                    <img src="../../assets/error-title.png" />
                </div>
                <div class="error-content" v-if="this.detail.topic[this.index]">
                    <div class="error-content-title">很遗憾，回答错误</div>
                    <div class="error-context">
                        <div class="error-answer">正确答案</div>
                        <div>
                            <div
                                class="error-context-active"
                                v-for="(item, i) in detail.topic[index]
                                    .topic_correct_answer"
                                :key="i"
                            >
                                {{
                                    detail.topic[index].topic_answer_options[
                                        item - 1
                                    ]
                                }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="error-btn" @click="next()">继续观看视频</div>
            </div>
        </van-popup>

        <van-popup v-model="showResult" round :close-on-click-overlay="false">
            <div class="pop-con result">
                <div class="result-title">本场视频已结束，感谢您的观看！</div>
                <div class="score" v-if="score && !isFinish">
                    恭喜获得积分
                    <img src="@/assets/score.png" alt="" />
                    <span class="success-num">+{{ score }}</span>
                </div>
                <div class="result-count" v-if="result.length">
                    本视频一共<span>{{ result.length }}</span
                    >题，您总共答对<span class="result-count-correct">{{
                        answerCount
                    }}</span
                    >题
                </div>
                <div class="result-list" ref="list">
                    <div
                        class="result-item"
                        v-for="(item, key) in result"
                        :key="key"
                    >
                        <div class="result-item-index">{{ key + 1 }}</div>
                        <div class="result-item-title">{{ item.title }}</div>
                        <div class="result-item-context">
                            <div class="result-item-correct-answer">
                                正确答案
                            </div>
                            <div>
                                <div
                                    v-for="(j, i) in item.answer.split(',')"
                                    :key="i"
                                    class="result-item-answer"
                                >
                                    {{ j }}
                                </div>
                            </div>
                        </div>
                        <div class="result-item-bey">
                            <span class="yes" v-if="item.is_correct == 1"
                                >正确</span
                            >
                            <span class="no" v-else>错误</span>
                        </div>
                    </div>
                </div>
                <div class="result-btns">
                    <div class="result-btn-dft" @click="goIndex">返回首页</div>
                    <div class="result-btn" @click="goRefresh">再看一遍</div>
                </div>
            </div>
        </van-popup>

        <van-popup v-model="showAnswer" round :close-on-click-overlay="false">
            <div class="pop-con answer" v-if="detail.topic">
                <div class="answer-title">
                    <div class="answer-title-text">第{{ index + 1 }}题</div>
                    <div class="answer-title-count">
                        倒计时{{ subjectTime }}秒
                    </div>
                </div>
                <div class="answer-context" v-if="detail.topic[index]">
                    <span style="font-weight: 600">{{
                        detail.topic[index].topic_type == 1
                            ? "[单选题]"
                            : "[多选题]"
                    }}</span>
                    {{ detail.topic[index].topic_title }}
                </div>
                <div class="answer-radio" v-if="detail.topic[index]">
                    <van-radio-group
                        v-if="detail.topic[index].topic_type == 1"
                        v-model="option"
                        checked-color="#F8B302"
                        icon-size="16"
                    >
                        <div class="radio">
                            <div
                                class="box"
                                v-for="(option, key) in detail.topic[index]
                                    .topic_answer_options"
                                :key="key"
                            >
                                <van-radio :name="key">{{ option }}</van-radio>
                            </div>
                        </div>
                    </van-radio-group>
                    <van-checkbox-group
                        checked-color="#F8B302"
                        v-model="multipleOption"
                        v-else
                    >
                        <van-checkbox
                            :name="key"
                            v-for="(option, key) in detail.topic[index]
                                .topic_answer_options"
                            :key="key"
                            style="margin-bottom: 20px"
                            >{{ option }}</van-checkbox
                        >
                    </van-checkbox-group>
                </div>

                <!-- <div
                    class="answer-btn"
                    @click="addAnswer(detail.topic[index].topic_type, index)"
                >
                    提交
                </div> -->
                <Btn
                    :loading="loading"
                    @click="addAnswer(detail.topic[index].topic_type, index)"
                    >提交</Btn
                >
            </div>
        </van-popup>
    </div>
</template>

<script>
import {
    answerDetail,
    answerSubmit,
    answerResult,
    answerIntegralInfo,
} from "../../util/api";
// import sign from "../../components/sign";
import headerBox from "@/components/headerBox";
import Btn from "@/components/btn.vue";
import { Toast } from "vant";
import { pageMixin } from "@/util/pageMixin";
import { indexJssdk } from "@/util/api";
import { share } from "@/util/share";
export default {
    mixins: [pageMixin],
    name: "Questionnaire",
    components: {
        // sign,
        Btn,
        headerBox,
    },
    data() {
        return {
            showAnswer: false,
            topicType: 1,
            showResult: false,
            showError: false,
            showSuccess: false,
            option: "",
            multipleOption: [],
            query: {
                task_id: 0,
                group_id: 0,
            },
            detail: {
                status: 0,
            },
            index: 0,
            time: 0,
            day: 0,
            hour: 0,
            minute: 0,
            second: 0,
            play: true,
            timeObj: {},
            subjectTime: 0,
            coverUrl: "",
            videoObj: {},
            current: 0,
            subjectTimeObj: {},
            form: {
                topic_id: 0,
                is_correct: 0,
                answer: "",
            },
            answerCount: 0,
            result: [],
            parent: 0,
            isend: false,
            curWidth: 0,
            totalTime: 0,
            totalTimeStr: "",
            currentTimeStr: "",
            flag: false,
            score: 0,
            isFinish: false,
            loading: false,
        };
    },
    mounted() {
        // this.indexJssdk();
        this.query.id = this.$route.query.answer_id;
        this.form.answer_id = parseInt(this.$route.query.answer_id);
        this.getData();
    },
    methods: {
        // 视频自定义分享
        shareSession(){
            let liveInfo = this.detail.liveInfo
             let base_url = location.href.split("#")[0];
            let url = location.href;
            
            indexJssdk({ url: base_url }).then(res => {
                if (res.code == 0) {
                    share(
                        res.data.appId,
                        res.data.timestamp,
                        res.data.nonceStr,
                        res.data.signature,
                        liveInfo.answer_share_title,
                        liveInfo.answer_share_describe,
                        liveInfo.answer_share_img,
                        url
                    );
                }
            });
        },
        // 查询积分
        async answerIntegralInfo() {
            try {
                const { code, data } = await answerIntegralInfo({
                    answer_id: this.$route.query.answer_id,
                });
                if (code === 0) {
                    this.score = data.right_score;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async answerResult() {
            try {
                const { data, code } = await answerResult({
                    answer_id: this.$route.query.answer_id,
                });
                if (code == 0) {
                    this.isFinish = data.length == this.detail.topic.length;
                }
            } catch (error) {
                console.log(error);
            }
        },

        getData() {
            let that = this;
            answerDetail(this.query).then(res => {
                this.answerResult();
                if (res.code == 0) {
                    that.detail = res.data;
                    this.shareSession()
                    setTimeout(function () {
                        // that.loadPlay();
                        that.play = false;
                        that.$refs.myVideo.controls = false;
                        that.$refs.myVideo.addEventListener(
                            "timeupdate",
                            that.timeupdate
                        );
                        that.$refs.myVideo.addEventListener(
                            "ended",
                            that.ended
                        );
                    }, 1000);
                }
            });
        },
        loadPlay() {
            let that = this;
            setTimeout(function () {
                that.$refs.myVideo.play();
                that.play = true;
            }, 500);
        },
        timeupdate() {
            let that = this;
            var timeDisplay;
            //用秒数来显示当前播放进度
            timeDisplay = Math.floor(that.$refs.myVideo.currentTime);
            this.totalTime = video.duration;
            this.totalTimeStr = this.getTimeStr(
                parseFloat(this.totalTime.toFixed(2))
            );
            this.currentTimeStr = this.getTimeStr(
                parseFloat(timeDisplay.toFixed(2))
            );
            let totalWidth = document.body.clientWidth;
            this.curWidth =
                (parseFloat(timeDisplay.toFixed(2)) /
                    parseFloat(this.totalTime.toFixed(2))) *
                100;
            that.$refs.progress.resize();
            if (!this.isend) {
                //当视频播放到 4s的时候做处理
                that.detail.topic.forEach(function (item) {
                    that.current = that.$refs.myVideo.currentTime;
                    if (
                        parseInt(timeDisplay) ==
                            parseInt(item.topic_node_time) &&
                        parseInt(that.parent) != parseInt(that.current)
                    ) {
                        that.parent = that.current;
                        that.showAnswer = true;
                        that.$refs.myVideo.pause();
                        that.play = false;
                        that.form.topic_id = parseInt(item.id);
                        that.subjectTime = 30;
                        that.subjectTimeObj = setInterval(function () {
                            if (that.subjectTime <= 0) {
                                clearInterval(that.subjectTimeObj);
                                that.submitAnswer(
                                    that.detail.topic[that.index].topic_type
                                );
                            } else {
                                that.subjectTime = that.subjectTime - 1;
                            }
                        }, 1000);
                    }
                });
            }
        },
        getTimeStr(time) {
            let str;
            let h = parseInt((time / 60 / 60) % 24);
            h = h < 10 ? "0" + h : h;
            let m = parseInt((time / 60) % 60);
            m = m < 10 ? "0" + m : m;
            let s = parseInt(time % 60);
            s = s < 10 ? "0" + s : s;
            // 作为返回值返回
            let hourstr = "";
            if (h > 0) {
                hourstr = h + ":";
            }
            str = hourstr + m + ":" + s;
            return str;
        },

        addAnswer(type, index) {
            let that = this;
            if (that.option === "" && type == 1) return Toast("请选择答案");
            if (that.multipleOption.length === 0 && type == 2)
                return Toast("请选择答案");
            if (this.flag) return;
            that.submitAnswer(type);
        },
        submitAnswer(type, index) {
            this.loading = true;
            //组装数据
            this.flag = true;
            if (type == 1) {
                //单选
                this.form.is_correct =
                    this.option ===
                    this.detail.topic[this.index].topic_correct_answer[0] - 1
                        ? 1
                        : 0;
                this.form.answer = Number(this.option) + 1;
            } else {
                //   多选
                this.form.is_correct =
                    this.detail.topic[this.index].topic_correct_answer.join() ==
                    this.multipleOption
                        .sort()
                        .map(item => item + 1)
                        .join()
                        ? 1
                        : 0;
                this.form.answer =
                    this.multipleOption
                        .sort()
                        .map(item => item + 1)
                        .join() || 1;
            }

            answerSubmit(this.form)
                .then(res => {
                    if (res.code == 0) {
                        this.showAnswer = false;
                        this.option = "";
                        this.multipleOption = [];
                        if (this.detail.topic[this.index]) {
                            this.result.push({
                                is_correct: this.form.is_correct,
                                title: this.detail.topic[this.index]
                                    .topic_title,
                                answer:
                                    type == 1
                                        ? this.detail.topic[this.index]
                                              .topic_answer_options[
                                              this.detail.topic[this.index]
                                                  .topic_correct_answer[0] - 1
                                          ]
                                        : this.detail.topic[
                                              this.index
                                          ].topic_correct_answer
                                              .map(item => {
                                                  return this.detail.topic[
                                                      this.index
                                                  ].topic_answer_options[
                                                      item - 1
                                                  ];
                                              })
                                              .join(),
                            });
                        }
                        if (this.index != this.detail.topic.length - 1) {
                            if (this.form.is_correct == 1) {
                                this.showSuccess = true;
                            } else {
                                this.showError = true;
                            }
                        } else {
                            if (this.form.is_correct == 1) {
                                this.showSuccess = true;
                            } else {
                                this.showError = true;
                            }
                            // this.getResult();
                        }
                        clearInterval(this.subjectTimeObj);
                    } else {
                        Toast(res.msg);
                    }
                    this.loading = false;
                    this.flag = false;
                })
                .catch(() => {
                    this.loading = false;
                });
        },
        ended() {
            this.answerIntegralInfo();
            this.showResult = true;
            this.isend = true;
            this.getResult();
        },
        next() {
            //开始播放下一题
            this.showSuccess = false;
            this.showError = false;
            this.current = 0;
            this.$refs.myVideo.play();
            this.play = true;
            let that = this;
            setTimeout(function () {
                that.index++;
            }, 500);
        },
        changeStop() {
            if (this.play == true) {
                this.$refs.myVideo.pause();
                this.play = false;
            } else {
                this.$refs.myVideo.play();
                this.play = true;
            }
        },
        playOperation() {
            this.$refs.myVideo.play();
            this.play = true;
        },
        getResult() {
            let that = this;
            this.result.forEach(function (item) {
                if (item.is_correct == 1) {
                    that.answerCount++;
                }
            });
        },
        addTime(m) {
            return m < 10 ? "0" + m : m;
        },
        // 时间转换
        dateformat(micro_second) {
            // 总秒数
            var second = micro_second;
            // 天数
            var day = Math.floor(second / 3600 / 24);
            // 小时
            var hr = Math.floor((second / 3600) % 24);
            // 分钟
            var min = Math.floor((second / 60) % 60);
            // 秒
            var sec = Math.floor(second % 60);

            this.day = day;
            this.hour = hr;
            this.minute = min;
            this.second = sec;
        },
        goIndex() {
            this.$router.push({
                path: "/index",
            });
        },
        goRefresh() {
            this.$refs.myVideo.play();
            this.showResult = false;
            this.isend = false;
            let that = this;
            setTimeout(function () {
                that.parent = 0;
                that.current = 0;
                that.index = 0;
                that.result = [];
                that.answerCount = 0;
            }, 500);
        },
    },
};
</script>
<style lang="scss" scoped>
//视频隐藏

//隐藏进度条
video::-webkit-media-controls-timeline {
    display: none !important;
}

//隐藏全屏按钮
video::-webkit-media-controls-fullscreen-button {
    display: none;
}

//隐藏播放按钮
video::-webkit-media-controls-play-button {
    display: none;
}

//观看的当前时间
video::-webkit-media-controls-current-time-display {
    display: none;
}

//剩余时间
video::-webkit-media-controls-time-remaining-display {
    display: none;
}

//音量按钮
video::-webkit-media-controls-mute-button {
    display: none;
}

video::-webkit-media-controls-toggle-closed-captions-button {
    display: none;
}

#video {
    width: 100vw;

    height: calc(100vh - 44px);
    background: #000000;
}

.header {
    width: 100%;
    height: 50px;

    display: flex;
    align-items: center;
    background-color: #ffffff;

    .logo {
        // margin-left: 15px;
        width: 104px;
        text-align: center;
        img {
            height: 40px;
        }
    }

    .tit {
        flex: 1;
        text-align: center;
        padding-right: 120px;
        box-sizing: border-box;
        font-size: 15px;
    }
}

.video-cover {
    width: 100vw;
    height: calc(100vh - 52px);
    position: fixed;
    top: 50px;
    left: 0;
}

.video-mask {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: #000000;
    opacity: 0.4;
}

.video-cover-content {
    position: relative;
    width: 100vw;
    height: calc(100vh - 50px);
}

.video-cover img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.video-play {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 55px;
    width: 50px;
}

.video-play img {
    width: 100%;
    height: 100%;
}

.title {
    position: fixed;
    top: 65px;
    left: 20px;
    font-size: 16px;
    color: #ffffff;
    z-index: 100;
}

.video-end {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.video-end-title {
    color: #ffffff;
    width: 250px;
    text-align: center;
}

.video-end-btn {
    width: 230px;
    margin: 70px auto 0;
    text-align: center;
    height: 45px;
    line-height: 45px;
    background: var(--color-main);
    box-shadow: 0px 2px 5px 0px rgba(203, 17, 27, 0.25);
    border-radius: 22px;
    color: #ffffff;
}

.video-start {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.video-start-title {
    font-size: 18px;
    color: #ffffff;
    text-align: center;
}

.video-start-current {
    display: flex;
    margin-top: 60px;
}

.video-start-item {
    display: flex;
    align-items: flex-end;
}

.video-start-item-time {
    min-width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    color: #ffffff;
    margin: 0 5px;
    border-radius: 10px;
    font-size: 16px;
    background-color: rgba($color: #000000, $alpha: 0.65);
}

.video-start-item-text {
    color: #ffffff;
    height: 40px;
    text-align: center;
    line-height: 40px;
    font-size: 16px;
}

.video-start-content {
    margin-top: 30px;
    font-size: 14px;
    padding: 10px;
    background-color: rgba($color: #000000, $alpha: 0.65);
    border-radius: 10px;
    color: #ffffff;
}

.video-end-content {
    margin-top: 10px;
    font-size: 14px;
    padding: 10px;
    background-color: rgba($color: #000000, $alpha: 0.65);
    border-radius: 10px;
    color: #ffffff;
}

.success {
    width: 283.5px;
    padding-bottom: 35px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.success-title {
    margin-top: -10px;
    width: 150px;
    height: 34px;
}

.success-title img {
    width: 100%;
    height: 100%;
}

.success-content {
    margin-top: 27.5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.success-icon {
    width: 29px;
    height: 29px;
}

.success-icon img {
    width: 100%;
    height: 100%;
}

.success-context {
    margin-top: 20px;
    font-size: 17px;
    color: #cb9200;
    text-align: center;
}

.success-btn {
    margin: 35px 16px 0;
    width: 200px;
    text-align: center;
    height: 44px;
    line-height: 44px;
    background: var(--color-main);
    box-shadow: 0px 5px 10px 0px rgba(203, 17, 27, 0.25);
    border-radius: 44px;
    color: #ffffff;
    font-size: 16px;
}

.error {
    width: 283.5px;
    padding-bottom: 35px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.error-title {
    margin-top: -10px;
    width: 150px;
    height: 34px;
}

.error-title img {
    width: 100%;
    height: 100%;
}

.error-content {
    margin-top: 27.5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.error-icon {
    width: 29px;
    height: 29px;
}

.error-icon img {
    width: 100%;
    height: 100%;
}

.error-btn {
    margin: 35px 16px 0;
    width: 200px;
    text-align: center;
    height: 44px;
    line-height: 44px;
    background: var(--color-main);
    box-shadow: 0px 5px 10px 0px rgba(203, 17, 27, 0.25);
    border-radius: 44px;
    color: #ffffff;
    font-size: 16px;
}

.error {
    width: 283.5px;
    padding-bottom: 35px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.error-title {
    margin-top: -10px;
    width: 150px;
    height: 34px;
}

.error-title img {
    width: 100%;
    height: 100%;
}

.error-content {
    width: 100%;
    margin-top: 27.5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.error-icon {
    width: 29px;
    height: 29px;
}

.error-icon img {
    width: 100%;
    height: 100%;
}

.error-content-title {
    font-size: 17px;
    color: var(--color-main);
    text-align: center;
}
.error-answer {
    width: 80px;
}
.error-context {
    // display: flex;
    margin-top: 15px;
    width: 100%;
    font-size: 16px;
    color: #333333;
    padding: 10px 15px 15px 15px;
    box-sizing: border-box;
}

.error-btn {
    margin: 35px 16px 0;
    width: 200px;
    text-align: center;
    height: 44px;
    line-height: 44px;
    background: var(--color-main);
    box-shadow: 0px 5px 10px 0px rgba(203, 17, 27, 0.25);
    border-radius: 44px;
    color: #ffffff;
    font-size: 16px;
}

.error-context-active {
    margin-top: 10px;
    color: #999;
    font-size: 16px;
}

.video-content {
    width: calc(100vw - 50px);
}

.result {
    padding: 23px 15px 40px;
    width: calc(100vw - 50px);
}

.van-popup {
    overflow-y: unset !important;
}

.result-title {
    font-size: 16px;
    text-align: center;
}

.result-count {
    margin-top: 25px;
    font-size: 14px;
    text-align: center;
    &-correct {
        color: var(--color-main);
        font-weight: 700;
    }
}

.result-list {
    height: 100%;
    overflow-y: scroll;
    max-height: 270px;
    margin-top: 4px;
}
.result-item-answer {
    margin-bottom: 7px;
    color: #dc9e00;
}

.result-item {
    margin-top: 10px;
    position: relative;
    background: #ffffff;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    padding: 11px 27px;
}

.result-item .acitve {
    background: #fceff0;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.07);
}

.result-item-index {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 20px;
    height: 20px;
    background: #fff1cd;
    color: #cb9200;
    font-size: 14px;
    border-radius: 10px 2px 10px 2px;
    text-align: center;
}

.result-item-title {
    font-size: 14px;
    color: #333333;
}

.result-item-context {
    font-size: 14px;
    color: #999999;
}

.result-item-context span {
    color: #dc9e00;
}

.result-item-bey {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 40px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    background: #faf4f5;
    color: var(--color-main);
    font-size: 12px;
    border-radius: 10px 2px 10px 2px;
}

.result-btns {
    display: flex;
    justify-content: space-between;
    margin: 30px 15px 0;
}

.result-btn-dft {
    width: 117px;
    height: 40px;
    background: #ffffff;
    border: 1px solid var(--color-main);
    border-radius: 22px;
    line-height: 40px;
    text-align: center;
    color: var(--color-main);
    font-size: 16px;
}

.result-btn {
    width: 117px;
    height: 40px;
    background: var(--color-main);
    box-shadow: 0px 5px 10px 0px rgba(203, 17, 27, 0.25);
    border-radius: 22px;
    line-height: 40px;
    text-align: center;
    color: #ffffff;
    font-size: 16px;
}

.answer {
    padding: 23px 17px 40px;
    width: calc(100vw - 55px);
}

.answer-title {
    margin-top: -70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url(../../assets/title.png);
    background-size: 100% 100%;
}

.answer-title-text {
    padding-top: 4px;
    padding-bottom: 4px;
    color: #af6304;
    font-size: 16px;
}

.answer-title-count {
    font-size: 12px;
    color: #af6304;
    padding-bottom: 22px;
}

.answer-context {
    margin-top: 15px;
    margin-bottom: 30px;
    font-size: 18px;
    text-align: center;
}

.box {
    margin-bottom: 20px;
}

.answer-btn {
    width: 230px;
    height: 45px;
    background: var(--color-main);
    box-shadow: 0px 2px 5px 0px rgba(203, 17, 27, 0.25);
    border-radius: 23px;
    margin: 15px auto 0;
    line-height: 45px;
    font-size: 16px;
    color: #ffffff;
    text-align: center;
}
.yes {
    color: #cb9200;
}

.icon-shouye {
    font-size: 25px;
    color: #333;
}

.progress {
    position: fixed;
    bottom: 20px;
    left: 15px;
    width: calc(100vw - 30px);
    height: 30px;
    display: flex;
    align-items: center;
}

.current-time {
    font-size: 14px;
    color: #ffffff;
    margin-right: 15px;
}

.end-time {
    font-size: 14px;
    color: #ffffff;
    margin-left: 15px;
}

.progress-content {
    flex: 1;
}
.score {
    text-align: center;
    img {
        width: 51px;
        height: 39px;
        transform: translateY(10px);
    }
}
.success-num {
    color: var(--color-main);
    font-weight: 700;
}
.result-item-correct-answer {
    padding: 10px 0px;
}
</style>
