<template>
  <div class="box">
    <div class="box-tabs">
      <div class="box-tabs-tab">
        <div
          v-for="(item, index) in list"
          :key="item.index"
          :class="active == index ? 'active' : ''"
          @click="changeTab(index, item.index)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      default: [],
    },
  },
  data() {
    return {
      active: 0,
    };
  },
  methods: {
    changeTab(index, key) {
      this.active = index;
      this.$emit("changeStatus", key);
    },
    reset() {
      this.active = 0;
    },
  },
};
</script>

<style scoped lang="scss">
.box {
  padding: 10px 15px 0;
  background: #fff;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.05);
  &-tabs {
    font-size: 14px;
    color: #999999;

    &-tab {
      display: flex;
      > div {
        margin-right: 15px;
      }
    }
  }
}
.active {
  color: var(--color-main);
  font-weight: 700;
  padding-bottom: 10px;
  border-bottom: 2px solid var(--color-main);
}
</style>