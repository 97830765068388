<template>
  <div class="page">
  <div>
      <div class="swiper-box">
      <van-swipe :height="swiperHeight" :autoplay="3000" :show-indicators="false">
        <van-swipe-item
          v-for="(banner, index) in getTopNewsList"
          :key="index"
          @click="toDetails(banner.id, banner)"
        >
          <img :src="banner.image" />
          <div class="banner_title">{{banner.title}}</div>
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="hot-font" v-if="hotNewsList.length">热点新闻</div>
    <div style="background: #f4f4f4">
      <div class="hot-news">
        <div
          class="hot-news-box"
          v-for="item in hotNewsList"
          :key="item.id"
          @click="toDetails(item.id, item)"
        >
          <img class="hot-news-box-img" :src="item.image" alt="" />
          <div class="hot-news-title">
            {{ item.title }}
          </div>
        </div>
      </div>
      <div class="news-box">
        <div class="top-news">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
          >
            <div
              class="top-news-list"
              v-for="item in headNewsList"
              :key="item.id"
              @click="toDetails(item.id, item)"
            >
              <div style="flex: 1">
                <div class="top-news-list-title">
                  {{ item.title }}
                </div>
                <div class="top-news-list-foot">
                  <div>
                    <img
                      class="top-news-list-foot-img"
                      src="@/assets/open-eye.png"
                      alt=""
                    />
                    {{ item.view }}
                  </div>
                  <div>
                    {{ item.updated_at && item.updated_at.substr(0, 10) }}
                  </div>
                </div>
              </div>
              <div>
                <img class="top-news-list-right" :src="item.image" alt="" />
              </div>
            </div>
          </van-list>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import {
  getHeadNews,
  getHotNews,
  getTopNews,
  getNewsDetail,
} from "@/util/api.js";
export default {
  data() {
    return {
      swiperHeight: "180",
      headNewsList: [],
      hotNewsList: [],
      getTopNewsList: [],
      loading: false,
      finished: false,
      query: {
        page: 1,
        limit: 10,
      },
    };
  },
  created() {
    this.getHotNews();
    this.getTopNews();
  },
  mounted() {
    this.$nextTick(() => {
      this.swiperHeight =
       document.querySelector(".swiper-box") && document.querySelector(".swiper-box").offsetWidth / 1.7;
      window.addEventListener("resize", () => {
        this.swiperHeight =
           document.querySelector(".swiper-box")  && document.querySelector(".swiper-box").offsetWidth / 1.7;
      });
    });
  },
  computed: {
    swiperHeightStyle() {
      return { height: this.swiperHeight + "px" };
    },
  },
  methods: {
    async toDetails(id, item) {
      if (item.is_link == 1) {
        try {
          await getNewsDetail({
            news_id: id,
          });
          location.href = item.url;
        } catch (error) {
          console.log(error);
        }
      } else {
        this.$router.push({
          path: "/newsDetails",
          query: {
            news_id: id,
          },
        });
      }
    },
    onLoad() {
      this.getHeadNews();
    },
    //  获取置顶新闻
    async getHeadNews() {
      try {
        const { data, code } = await getHeadNews(this.query);
        if (code === 0) {
          this.loading = false;
          if (this.query.page == 1) {
            this.headNewsList = data.data;
          } else {
            this.headNewsList.push(...data.data);
          }
          if (data.data.length < 10) {
            this.finished = true;
          }

          this.query.page++;
        }
      } catch (error) {
        console.log(error);
      }
    },
    //  获取热点新闻
    async getHotNews() {
      try {
        const { data, code } = await getHotNews();
        if (code === 0) {
          this.hotNewsList = data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    //  获取顶部新闻
    async getTopNews() {
      try {
        const { data, code } = await getTopNews();
        if (code === 0) {
          this.getTopNewsList = data;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.swiper-box {
  width: calc(100vw - 30px);
  margin: 10px auto;
  border-radius: 10px;
  position: relative;
  img {
    object-fit: cover;
    width: calc(100vw - 30px);
    width: 100%;
    border-radius: 10px;
  }
}
.banner_title {
  position: absolute;
  bottom: 0;
  color: #fff;
  width: 100%;
  height: 30px;
  padding: 0 20px;
  font-size: 15px;
  line-height: 30px;
  border-radius: 0 0 10px 10px;
  overflow: hidden; //超出的文本隐藏
  text-overflow: ellipsis; //溢出用省略号显示
  display: -webkit-box;
  -webkit-line-clamp: 1; // 超出多少行
  -webkit-box-orient: vertical;
  background-image: -moz-linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  background-image: -webkit-linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  background-image: -ms-linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0) 100%
  );
}
.hot-news {
  display: flex;
  overflow: auto;
  padding: 10px 0 10px 0;
  padding-left: 6px;
  box-sizing: border-box;
  &-title {
    padding: 10px 10px 0px 10px;
    overflow: hidden; //超出的文本隐藏
    text-overflow: ellipsis; //溢出用省略号显示
    display: -webkit-box;
    -webkit-line-clamp: 2; // 超出多少行
    -webkit-box-orient: vertical;
  }
  &-box {
    flex-shrink: 0;
    width: 120px;
    height: 130px;
    margin: 4px;
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.03);
    border-radius: 5px;
    img {
      border-radius: 5px 5px 0 0;
      width: 100%;
      height: 70px;
    }
  }
}
//隐藏滚动条
.hot-news::-webkit-scrollbar {
  display: none;
}
.hot-font {
  font-size: 16px;
  color: #333;
  padding: 15px 0 10px 15px;
}
.swiper-box {
  width: calc(100vw - 30px);
  margin: 10px auto;
  border-radius: 10px;
  img {
    object-fit: cover;
    width: calc(100vw - 30px);
    width: 100%;
    border-radius:  10px;
  }
}
.news-box {
  padding: 10px 12px;
}
.top-news {
  background: #fff;
  border-radius: 10px;
  margin-bottom: 40px;
  &-list {
    display: flex;
    padding: 10px 15px;
    justify-content: space-between;
    margin-bottom: 15px;
    &-foot {
      display: flex;
      color: #999999;
      font-size: 14px;
      display: flex;
      justify-content: space-between;
    }
    &-title {
      overflow: hidden; //超出的文本隐藏
      text-overflow: ellipsis; //溢出用省略号显示
      display: -webkit-box;
      -webkit-line-clamp: 2; // 超出多少行
      -webkit-box-orient: vertical;
      height: 42px;
      margin-bottom: 17px;
    }
    .top-news-list-right {
      width: 120px;
      height: 70px;
      border-radius: 5px;
      margin-left: 15px;
    }
    .top-news-list-foot-img {
      width: 15px;
      height: 12px;
    }
  }
}
</style>