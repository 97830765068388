<template>
    <div>
        <div class="btn" :class="[style, isShaDow]">
            <van-loading size="24px" class="loading" v-if="loading">{{
                loadingText
            }}</van-loading>
            <div v-else @click="click"><slot /></div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        type: {
            type: String,
            default: "solid",
        },
        loading: {
            type: Boolean,
            default: false,
        },
        loadingText: {
            type: String,
            default: "",
        },
        shadow: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        style() {
            return `btn-${this.type}`;
        },
        isShaDow() {
            return this.shadow ? "btn-shadow" : "";
        },
    },
    methods: {
        click() {
            this.$emit("click");
        },
    },
};
</script>

<style lang="scss" scoped>
.btn {
    text-align: center;
    font-size: 16px;
    box-sizing: border-box;
    padding: 10px 15px;
    height: 42px;
    border-radius: 55px;
    &-shadow {
        box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.07);
    }
    &-solid {
        background: var(--color-main);
        color: #fff;
    }
    &-line {
        background: #fff;
        color: #000;
        border: 1px solid var(--color-main);
    }
    .loading {
        color: #fff;
    }
}
</style>
