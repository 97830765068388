<template>
    <!-- 答题详情 -->
    <div>
        <headerBox bgColor="#fff" backPath="/index">答题详情</headerBox>
        <div class="box">
            <div class="box-topic">
                <van-loading
                    class="loading"
                    v-if="!Object.keys(detail).length"
                />
                <div v-for="(item, index) in detail.topic" :key="item.id">
                    <topic
                        v-if="currentTopic == index"
                        way="operation"
                        ref="topic"
                        @inform="inform"
                        :step="step"
                        :total="detail.topic.length"
                        :loading.sync="loading"
                        v-bind="item"
                    ></topic>
                </div>
                <div
                    class="no-have"
                    v-if="detail.topic && detail.topic.length == 0"
                >
                    暂无题目
                </div>
            </div>
            <div v-if="detail.topic && detail.topic.length != 0">
                <div class="box-foot-submit" v-if="step == 1">
                    <Btn @click="submit" :loading="loading">提交</Btn>
                </div>
                <div v-else>
                    <div
                        class="box-foot-submit"
                        v-if="detail.topic.length - 1 > currentTopic"
                    >
                        <Btn @click="nextTopic">下一题</Btn>
                    </div>
                    <!-- 当最后一题显示完成答题 -->
                    <div class="box-foot-submit" v-else>
                        <Btn @click="finish">完成答题</Btn>
                    </div>
                </div>
            </div>
            <!-- 弹框 -->
            <van-dialog
                v-model="show"
                title="本次答题已结束,感谢您的参与"
                :showConfirmButton="false"
            >
                <div class="title">
                    共答题{{ detail.topic && detail.topic.length }}道,答对<span
                        class="success"
                        >{{ isTrueArr.filter(Boolean).length }}</span
                    >道
                </div>
                <div class="score" v-if="score && !isFinish">
                    恭喜获得积分
                    <img src="@/assets/score.png" alt="" />
                    <span class="success">+{{ score }}</span>
                </div>
                <div class="dialog">
                    <div>
                        <Btn type="line" @click="$router.push('/index')"
                            >返回首页</Btn
                        >
                    </div>
                    <div>
                        <Btn @click="again">再答一遍</Btn>
                    </div>
                </div>
            </van-dialog>
            <van-dialog
                v-model="suspendShow"
                title="上次还有答题未完成"
                :showConfirmButton="false"
            >
                <div class="dialog">
                    <div>
                        <Btn type="line" @click="suspendShow = false"
                            >重新开始</Btn
                        >
                    </div>
                    <div>
                        <Btn @click="goOn">继续答题</Btn>
                    </div>
                </div>
            </van-dialog>
            <!-- 弹框 -->
        </div>
    </div>
</template>

<script>
import topic from "@/components/topic.vue";
import Btn from "@/components/btn.vue";
import headerBox from "@/components/headerBox";
import { pageMixin } from "@/util/pageMixin";
import { answerDetail, answerResult, answerIntegralInfo } from "@/util/api.js";
export default {
    mixins: [pageMixin],
    components: {
        topic,
        Btn,
        headerBox,
    },
    data() {
        return {
            step: 1,
            show: false,
            suspendShow: false,
            total: 0,
            correctNum: 0,
            detail: {},
            currentTopic: 0,
            isTrueArr: [],
            date: "",
            recordNum: 0,
            recordTrue: [],
            score: 0,
            loading: false,
            isFinish: false,
        };
    },
    mounted() {
        this.indexJssdk();
        this.answerDetail();
    },
    methods: {
        // 获取题目详情
        async answerDetail() {
            const { code, data } = await answerDetail({
                id: this.$route.query.answer_id,
            });
            if (code == 0) {
                this.detail = data;
                this.record();
            }
        },
        submit() {
            this.$refs.topic[0].submit();
        },
        inform(step, inform) {
            this.step = step;
            this.isTrueArr.push(inform);
        },
        again() {
            this.record();
            this.show = false;
            this.currentTopic = 0;
            this.step = 1;
            this.isTrueArr = [];
        },
        // 完成答题
        async finish() {
            await this.answerIntegralInfo();
            this.show = true;
        },
        //下道题目
        nextTopic() {
            if (this.currentTopic < this.detail.topic.length - 1) {
                this.currentTopic++;
                this.step = 1;
            }
        },
        // 查询积分
        async answerIntegralInfo() {
            try {
                const { code, data } = await answerIntegralInfo({
                    answer_id: this.$route.query.answer_id,
                });
                if (code === 0) {
                    this.score = data.right_score;
                }
            } catch (error) {
                console.log(error);
            }
        },
        // 上次是否有遗留答题
        async record() {
            try {
                const { data, code } = await answerResult({
                    answer_id: this.$route.query.answer_id,
                });

                if (code == 0) {
                    // 检测是否已经答题完成
                    this.isFinish = data.length == this.detail.topic.length;
                    if (
                        data &&
                        data.length > 0 &&
                        this.detail.topic.length > data.length
                    ) {
                        this.recordNum = data.length;
                        data.forEach(item => {
                            this.recordTrue.push(item.is_correct);
                        });
                        this.suspendShow = true;
                    }
                }
            } catch (error) {
                console.log(error);
            }
        },
        // 跳至上次答题
        goOn() {
            this.currentTopic = this.recordNum;
            this.recordTrue.forEach(item => {
                this.isTrueArr.push(item);
            });
            this.suspendShow = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.box {
    min-height: calc(100vh - 44px);
    padding: 15px;
    box-sizing: border-box;
    background: #f8f8f8 !important;
    .loading {
        text-align: center;
    }
    &-topic {
        padding-bottom: 100px;
    }
    &-foot-submit {
        position: fixed;
        width: 100%;
        background: #fff;
        left: 0;
        bottom: 0;
        padding: 15px;
    }
    .title {
        padding: 25px;
        text-align: center;
    }
    .score {
        text-align: center;
        img {
            width: 51px;
            height: 39px;
            transform: translateY(10px);
        }
    }
    .no-have {
        text-align: center;
        color: #999;
    }
    .success {
        color: var(--color-main);
        font-weight: 700;
    }
    .dialog {
        padding: 25px;
        display: flex;
        > div {
            flex: 1;
            padding: 0 15px;
        }
    }
}
</style>
