<template>
    <div class="Mine">
        <div class="menu">
            <div class="header">个人中心</div>
            <div class="head">
                <div class="user">
                    <div class="top">
                        <div class="left">
                            {{ userInfo.name }}
                        </div>
                        <div class="right">
                            <img
                                src="@/assets/iphone.png"
                                alt=""
                                class="iphone"
                            />
                            {{ userInfo.mobile }}
                        </div>
                    </div>
                    <div class="bottom">
                        <img
                            src="@/assets/address.png"
                            alt=""
                            class="address"
                        />
                        {{ userInfo.company_name }}
                    </div>
                </div>
            </div>
            <div class="content">
                <div @click="$router.push('/punch')" class="commitment">
                    <img src="@/assets/commitment.png" alt="" />
                </div>
                <div class="ceil-box">
                    <div
                        v-for="item in ceilList"
                        :key="item.name"
                        class="ceil"
                        @click="$router.push(`${item.path}`)"
                    >
                        <div class="ceil-left">
                            <img :src="item.icon" />
                            <span> {{ item.name }}</span>
                        </div>
                        <div class="ceil-right">
                            <img src="../../assets/mine_1.png" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Btn type="line" @click="show = true" class="btn"
            ><span class="theme-color">退出登录</span></Btn
        >
        <!-- 弹框 -->
        <van-dialog
            v-model="show"
            title="确认退出登录"
            :showConfirmButton="false"
        >
            <div class="dialog">
                <div>
                    <Btn type="line" @click="show = false">取消</Btn>
                </div>
                <div>
                    <Btn :loading="loading" @click="quitLogin">确认</Btn>
                </div>
            </div>
        </van-dialog>
    </div>
</template>

<script>
import { getuserinfo } from "../../util/api";
import { Toast } from "vant";
import { exitLogin } from "@/util/api";
import Btn from "@/components/btn.vue";
import { pageMixin } from "@/util/pageMixin";
export default {
    mixins: [pageMixin],
    components: {
        Btn,
    },
    name: "Mine",
    data() {
        return {
            loading: false,
            show: false,
            userInfo: {},
            list: "",
            ceilList: [
                {
                    name: "答题记录",
                    icon: require("../../assets/feature_1.png"),
                    path: "/record",
                },
                {
                    name: "积分记录",
                    icon: require("../../assets/feature_2.png"),
                    path: "/integralRecord",
                },
                {
                    name: "错题库",
                    icon: require("../../assets/feature_3.png"),
                    path: "/errorLibrary",
                },
                {
                    name: "修改密码",
                    icon: require("../../assets/feature_4.png"),
                    path: "/userInfo",
                },
            ],
        };
    },
    mounted() {
         this.indexJssdk();
        this.getUserInfo();
    },
    methods: {
        async getUserInfo() {
            try {
                const { code, data } = await getuserinfo();
                if (code == 0) {
                    this.userInfo = data.data;
                }
            } catch (error) {
                console.log(error);
            }
        },
        // 退出登录
        async quitLogin() {
            try {
                this.loading = true;
                await exitLogin();
                localStorage.removeItem("UTOKEN");
                localStorage.removeItem("userInfo");
                Toast("退出成功");
                this.$router.replace("/login");
            } catch (error) {
                console.log(error);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
<style scoped lang="scss">
.content {
    padding-top: 0;
    min-height: 310px;
}
.theme-color {
    color: var(--color-main);
}
.Mine {
    background: #f8f8f8;
    min-height: 100vh;
}
.menu {
    min-height: calc(100vh - 130px);
}
.header {
    width: 100%;
    padding-top: 10px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    box-sizing: border-box;
    color: #000;
}
.head {
    background-image: url("../../assets/bg.png");
    background-size: 100% 100%;
    margin-top: -50px;
    height: 208px;
    padding-top: 100px;
    margin-bottom: 30px;
    .user {
        position: relative;
        margin: 0 15px 0;
        width: calc(100vw - 30px);
        background: #fef7f7;
        box-shadow: 0px 7px 9px 0px rgba(0, 0, 0, 0.05);
        border-radius: 10px;
        padding: 25px 20px;
        background: linear-gradient(0deg, #fdc73c 0%, #ffedbd 100%);
        .top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 20px;
            border-bottom: 1px solid #fedf90;
            .left {
                font-size: 20px;
                font-weight: bold;
                color: #111111;
            }
            .right {
                min-width: 120px;
                span {
                    position: relative;
                    top: 2px;
                    margin-right: -5px;
                    font-size: 20px;
                    background: linear-gradient(0deg, #eb4950, #ffd8da);
                    -webkit-background-clip: text;
                    color: transparent;
                }
                .iphone {
                    width: 13px;
                    height: 13px;
                }
            }
        }
        .bottom {
            margin-top: 20px;
            font-size: 14px;
            span {
                position: relative;
                top: 2px;
                margin-right: -5px;
                font-size: 20px;
                background: linear-gradient(0deg, #eb4950, #ffd8da);
                -webkit-background-clip: text;
                color: transparent;
            }
            .address {
                width: 13px;
                height: 15px;
                transform: translateY(2px);
            }
        }
        .l-b-bg {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 195px;
            height: 53px;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .r-t-bg {
            position: absolute;
            right: 0;
            top: 0;
            width: 221px;
            height: 92px;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}
.advertisement {
    position: fixed;
    bottom: 105px;
    left: 15px;
    right: 15px;
    width: calc(100vw - 30px);
    height: 70px;
}
.advertisement img {
    width: 100%;
    height: 100%;
}
.ceil-box {
    background: #fff;
    border-radius: 15px;
    padding: 15px;
    margin-bottom: 20px;
}
.ceil {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    &-left {
        > img {
            width: 24px;
            height: 23px;
            transform: translateY(5px);
        }
    }
    &-right {
        width: 9px;
        height: 15px;
        > img {
            width: 100%;
            height: 100%;
        }
    }
}

.btn {
    width: calc(100% - 30px);
    margin: 0 auto 20px;
    height: 120px;
}
.dialog {
    padding: 25px;
    display: flex;
    > div {
        flex: 1;
        padding: 0 15px;
    }
}
.commitment {
    img {
        width: 100%;
    }
}
</style>
