<template>
    <div>
        <headerBox bgColor="#fff">答题列表</headerBox>
        <!-- 答题记录 -->
        <div class="box">
            <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="onLoad"
            >
                <div
                    class="box-ceil"
                    @click="goDetails(item.answer_id, item.answer_type)"
                    v-for="item in recordList"
                    :key="item.id"
                >
                    <div class="box-ceil-title">
                        {{ item.title }}
                    </div>
                    <div class="box-ceil-line"></div>
                    <div class="box-ceil-foot">
                        <div class="box-ceil-foot-time">
                            <span>创建时间</span>
                            <span>{{ item.created_at }}</span>
                        </div>
                        <div class="box-ceil-foot-view">
                            <span>查看</span>
                            <van-icon name="arrow" />
                        </div>
                    </div>
                </div>
            </van-list>
        </div>
    </div>
</template>

<script>
import { userAnswerList } from "@/util/api";
import { Toast } from "vant";
import headerBox from "@/components/headerBox";
import { pageMixin } from "@/util/pageMixin";
export default {
    mixins: [pageMixin],
    components: {
        headerBox,
    },
    data() {
        return {
            recordList: [],
            query: {
                page: 1,
                page_size: 10,
            },
            loading: false,
            finished: false,
        };
    },
    created() {
         this.indexJssdk();
    },
    methods: {
        onLoad() {
            this.userAnswerList();
        },
        goDetails(answer_id, answer_type) {
            this.$router.push({
                // path: answer_type == 2 ? "recordDetail" : "video",
                path: "recordDetail",
                query: {
                    answer_id,
                    form: "record",
                },
            });
        },
        async userAnswerList() {
            try {
                const { data, code, msg } = await userAnswerList(this.query);
                if (code == 0) {
                    this.loading = false;
                    if (this.query.page == 1) {
                        this.recordList = data.data.list;
                    } else {
                        this.recordList.push(...data.data.list);
                    }
                    if (data.data.list.length < 10) {
                        this.finished = true;
                    }
                    this.query.page++;
                } else {
                    Toast(msg);
                }
            } catch (error) {
                console.log(error);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.box {
    padding: 15px;
    min-height: calc(100vh - 44px);
    background: #f8f8f8;
    &-ceil {
        background: #ffffff;
        border-radius: 5px;
        padding: 15px 0 15px 15px;
        box-sizing: border-box;
        margin-bottom: 5px;
        box-shadow: 0px 2px 8px 0px rgba(0, 111, 252, 0.07);
        &-title {
            font-weight: 600;
            color: #333333;
        }
        &-line {
            margin: 15px 15px 15px 0;
            height: 1px;
            background: #eaeaea;
        }
        &-foot {
            display: flex;
            justify-content: space-between;
            font-weight: 500;
            color: #999999;
            font-size: 12px;
            padding-right: 10px;
            &-time {
                span:first-child {
                    margin-right: 5px;
                }
            }
            &-view {
                font-size: 14px;
            }
        }
    }
}
</style>
