<template>
    <div>
        <div class="head" :style="{ background: bgColor }">
            <div class="head-box">
                <div v-if="isBack" class="head-box-arrow" @click="back">
                    <van-icon name="arrow-left" />
                </div>
                <div v-else></div>
                <div class="head-box-title">
                    <slot></slot>
                </div>
                <div class="head-box-right">
                    <van-icon v-if="isBack" name="arrow-left" />
                </div>
            </div>
        </div>
        <div class="placeholder"></div>
    </div>
</template>

<script>
export default {
    props: {
        bgColor: {
            default: "",
        },
        isBack: {
            default: true,
            type: Boolean,
        },
        backPath: {
            default: "",
            type: String,
        },
    },
    data() {
        return {};
    },
    methods: {
        back() {
            if (this.backPath) {
                this.$router.push(this.backPath);
                return;
            }
            this.$router.back();
        },
    },
};
</script>

<style lang="scss" scoped>
.head {
    position: fixed;
    top: 0;
    box-sizing: border-box;
    width: 100vw;
    height: 44px;
    line-height: 44px;
    font-size: 18px;
    z-index: 999;
    &-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 15px;
        box-sizing: border-box;
        &-right {
            visibility: hidden;
        }
    }
}
.placeholder {
    height: 44px;
}
</style>
