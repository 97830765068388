import { indexJssdk } from "@/util/api";
import { share } from "@/util/share";

export const pageMixin = {
    data() {
        return {};
    },
    methods: {
        indexJssdk() {
            let government_name = localStorage.getItem('userInfo') && JSON.parse(localStorage.getItem('userInfo')).government_name
            let base_url = location.href.split("#")[0];
            let url = location.href;
            let title = government_name  ? government_name + '云宣传智慧工作平台' : '云宣传智慧工作平台'

            let context = "每日安全承诺打卡，安全知识问卷、视频观看及答题";
            let icon_url =
                "https://cloud-publicity.oss-cn-hangzhou.aliyuncs.com/src/lQDPJxZ5yH3uGpnNAv3NBmSwg2ofJJAn2ZQCyJiJssBvAA_1636_765.jpg";
            indexJssdk({ url: base_url }).then(res => {
                if (res.code == 0) {
                    share(
                        res.data.appId,
                        res.data.timestamp,
                        res.data.nonceStr,
                        res.data.signature,
                        title,
                        context,
                        icon_url,
                        url
                    );
                }
            });
        },
    },
};
