<template>
  <div class="content">
    <div class="tabs">
      <div
        class="tab"
        v-for="(item, key) in list"
        :key="item.id"
        @click="changeTab(item.index, key)"
      >
        <div :class="['text', index == key ? 'active' : '']">
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  props: {
    list: Array,
  },
  name: "sign",
  data() {
    return {
      index: 0,
    };
  },
  methods: {
    changeTab(index, key) {
      this.index = key;
      this.$emit("changeTab", index);
    },
  },
};
</script>

<style scoped>
.content{
  padding: 0 0 14px 0;
}
.tabs {
  display: flex;
  margin: 15px 15px 0;
  padding: 15px 0;
  height: 55px;
  background-color: #ffffff;
  border-radius: 10px;
}
.tab {
  flex: 1;
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.text {
  position: absolute;
  top: 0px;
  z-index: 99;
  color: #666;
}
.active {
  color: var(--color-main);
}
.tab:nth-child(2) {
  border-left: 1px solid #eeeeee;
}
</style>