<template>
    <div>
        <headerBox bgColor="#fff">打卡记录</headerBox>
        <div class="content">
            <div class="content-box">
                <div class="content-box-header">
                    <div>本月正常出勤 {{ normalDay }} 天</div>
                    <div>
                        <van-icon
                            @click="up"
                            class="arrow-left"
                            name="arrow-left"
                        />
                        &nbsp;
                        <span>{{
                            query.year + "年" + query.month + "月"
                        }}</span>
                        &nbsp;
                        <van-icon
                            @click="down"
                            name="arrow"
                            :style="{
                                color: isNext ? '#999' : '',
                            }"
                        />
                    </div>
                </div>
                <div class="content-box-calendar">
                    <div class="content-box-calendar-head">
                        <div class="week">日</div>
                        <div class="week">一</div>
                        <div class="week">二</div>
                        <div class="week">三</div>
                        <div class="week">四</div>
                        <div class="week">五</div>
                        <div class="week">六</div>
                    </div>
                    <div class="content-box-calendar-all">
                        <div
                            class="content-box-calendar-content"
                            v-if="!loading"
                        >
                            <div
                                class="week"
                                v-for="(item, index) in emptyNum"
                                :key="index + 'x'"
                            ></div>
                            <div
                                class="week"
                                v-for="(item, index) in punchData"
                                :key="index"
                            >
                                <div
                                    :class="[
                                        'box',
                                        getTime(item.date) > getTime()
                                            ? 'black'
                                            : 'not-clock',
                                        item.clock_status === 1 ? 'clock' : '',
                                        NotPunch.includes(item.week)
                                            ? 'gray'
                                            : '',
                                        activeDay === index + 1 ? 'days' : '',
                                    ]"
                                    @click="optionDate(index + 1, item)"
                                >
                                    {{ index + 1 }}
                                </div>
                            </div>
                        </div>
                        <div class="loading" v-else>
                            <van-loading type="spinner" />
                        </div>
                    </div>
                </div>

                <van-steps
                    direction="vertical"
                    active-icon="circle"
                    inactive-icon="circle"
                    :active="9999"
                >
                    <van-step v-for="item in currentDay" :key="item.id">
                        <div class="step-line">
                            <div>
                                {{ item.type_name }} {{ item.start_time }}~{{
                                    item.end_time
                                }}
                            </div>
                            <div @click="openImg(item)">
                                <span v-if="item.is_status === 1">
                                    {{ item.clock_time }}
                                </span>
                                <span class="yet">
                                    <img
                                        src="@/assets/not-yet.png"
                                        alt=""
                                        v-if="item.is_status === 1"
                                    />
                                    <!-- <img src="@/assets/yet.png" alt="" v-else /> -->
                                </span>
                                {{ item.is_status === 0 ? "未打卡" : "已打卡" }}
                                <van-icon
                                    v-if="item.is_status === 1"
                                    name="arrow"
                                    class="arrow-right"
                                />
                            </div>
                            <!-- 0：未打卡，1：已打卡 -->
                        </div>
                    </van-step>
                </van-steps>
            </div>
        </div>
    </div>
</template>

<script>
import { getClockList } from "@/util/api";
import headerBox from "@/components/headerBox";
import { YY, MM } from "@/util/com.js";
import { ImagePreview } from "vant";
import { pageMixin } from "@/util/pageMixin";
export default {
    mixins: [pageMixin],
    components: { headerBox },
    data() {
        return {
            isNext: true,
            punchData: [],
            normalDay: 0,
            currentDay: [],
            activeDay: new Date().getDate(),
            query: {
                year: YY(),
                month: new Date().getMonth() + 1,
                day: new Date().getDate(),
            },
            weeks: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
            NotPunch: [],
            loading: false,
        };
    },
    created() {
         this.indexJssdk();
        this.getClockList();
    },
    computed: {
        monthChange() {
            return this.query.month;
        },
        // 控制日历展示对应星期几
        emptyNum() {
            let year = this.query.year;
            let month = this.query.month;
            month > 10 ? month : "0" + month;
            return new Date(`${year}/${month}/01`).getDay();
        },
    },
    watch: {
        monthChange() {
            if (this.query.year == YY() && this.query.month == MM()) {
                this.isNext = true;
                this.query.day = new Date().getDate();
                this.activeDay = new Date().getDate();
            }
        },
    },
    methods: {
        getTime(time) {
            if (time) {
                return new Date(time).getTime();
            }
            return new Date().getTime();
        },
        async getClockList() {
            try {
                this.loading = true;
                const { data } = await getClockList(this.query);
                this.punchData = data.month.map(item => {
                    return {
                        week: this.weeks[new Date(item.date).getDay()],
                        ...item,
                    };
                });
                this.normalDay = data.normal_attendance;
                this.currentDay = data.clock;
                this.NotPunch = data.week;
            } catch (error) {
                console.log(error);
            } finally {
                this.loading = false;
            }
        },
        up() {
            if (this.loading) return;
            this.isNext = false;
            if (this.query.month === 1) {
                this.query.month = 12 + 1;
                this.query.year--;
            }
            this.query.month--;
            this.getClockList();
        },
        down() {
            if (this.loading) return;
            if (this.query.year == YY() && this.query.month == MM()) return;
            this.isNext = false;
            if (this.query.month === 12) {
                this.query.month = 1 - 1;
                this.query.year++;
            }
            this.query.month++;
            this.getClockList();
        },
        optionDate(index, item) {
            if (this.query.day === index) return;
            if (this.NotPunch.includes(item.week)) return;
            if (this.getTime(item.date) > this.getTime()) return;
            this.activeDay = index;
            this.query.day = index;
            this.getClockList();
        },
        openImg(item) {
            if (item.is_status === 1) {
                ImagePreview({
                    images: [item.img],
                    showIndex: false,
                });
            }
        },
        throttle(fn, time) {
            let canUse = true; // 设置一个开关
            return function () {
                if (!canUse) {
                    return false;
                } // 如果开关已经关掉了就不用往下了
                // if(canUse) fn.apply(this,arguments)//fn放这里是立即执行
                canUse = false; // 利用闭包刚进来的时候关闭开关
                setTimeout(() => {
                    fn.apply(this, arguments); //fn放这里是非立即执行，定时器结束才执行
                    canUse = true; // 执行完才打开开关
                }, time);
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.content {
    min-height: calc(100vh - 44px);
    background-color: #f8f8f8;
    margin-bottom: 10px;
    &-box {
        background: #fff;
        border-radius: 10px 10px 0px 0px;
        &-header {
            display: flex;
            justify-content: space-between;
            padding: 15px;
        }
    }
}
.content-box-calendar {
    padding: 0 10px;
    margin-bottom: 30px;
    &-head {
        display: flex;
        text-align: center;
        border-top: 1px solid #e5e5e5;
        padding: 10px 0;
    }
    &-content {
        display: flex;
        text-align: center;
        flex-wrap: wrap;
        .week {
            display: flex;
            justify-content: center;
            .box {
                width: 40px;
                height: 40px;
                line-height: 40px;
            }
        }
    }
    .week {
        width: 14%;
    }
}
.not-clock {
    color: #f8b302;
}
.clock {
    color: #008be5;
}
.black {
    color: #000;
}
.gray {
    color: #cccccc !important;
}
.days {
    border-radius: 50%;
    background-color: #008be5;
    color: #fff !important;
}
.yet {
    img {
        width: 12px;
        height: 12px;
    }
}
::v-deep .van-steps {
    height: 45vh;
    .van-step--finish .van-step__circle,
    .van-step--finish .van-step__line {
        background-color: #ebedf0 !important;
    }
    .van-step--vertical {
        padding: 20px 20px 20px 0;
        // padding: 0;
    }
}
.step-line {
    display: flex;
    justify-content: space-between;
}
::v-deep .van-hairline {
    padding-top: 10px !important;
}
.loading {
    text-align: center;
    min-height: 200px;
}
.arrow-right {
    color: #ccc;
}
</style>
