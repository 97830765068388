export function getScrollTop() {
    var scrollPos;
    if (window.pageYOffset) {
        scrollPos = window.pageYOffset;
    } else if (document.compatMode && document.compatMode != "BackCompat") {
        scrollPos = document.documentElement.scrollTop;
    } else if (document.body) {
        scrollPos = document.body.scrollTop;
    }
    return scrollPos;
}
export function base64ImgtoFile(dataurl, filename = "file") {
    let arr = dataurl.split(",");
    let mime = arr[0].match(/:(.*?);/)[1];
    let suffix = mime.split("/")[1];
    let bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], `${filename}.${suffix}`, {
        type: mime,
    });
}
export function FormatTime() {
    //返回显示的日期时间格式
    var date = new Date();
    var year = formatTime(date.getFullYear());
    var month = formatTime(date.getMonth() + 1);
    var day = formatTime(date.getDate());
    var hour = formatTime(date.getHours());
    var minute = formatTime(date.getMinutes());
    var second = formatTime(date.getSeconds());
    return `${year}-${month}-${day} ${hour}:${minute}:${second} `;
}

export function Time() {
    //返回显示的时间格式
    var date = new Date();
    var hour = formatTime(date.getHours());
    var minute = formatTime(date.getMinutes());
    return `${hour}:${minute} `;
}

export function DateYY() {
    //返回显示的日期格式
    var date = new Date();
    var year = formatTime(date.getFullYear());
    var month = formatTime(date.getMonth() + 1);
    var day = formatTime(date.getDate());
    return `${year}-${month}-${day}`;
}
export function YY() {
    //年
    var date = new Date();
    var year = formatTime(date.getFullYear());
    return `${year}`;
}
export function MM() {
    //月
    var date = new Date();
    var month = date.getMonth() + 1;
    return `${month}`;
}

function formatTime(n) {
    //判断是否需要加0
    if (n < 10) {
        return "0" + n;
    } else {
        return n;
    }
}

export const baseURL = "http://qslxy-test-api.ainifafa.com";
