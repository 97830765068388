<template>
    <div>
        <headerBox bgColor="#fff">错题库</headerBox>
        <div>
            <van-swipe
                class="my-swipe"
                indicator-color="white"
                :show-indicators="false"
                ref="swiper"
            >
                <van-swipe-item v-for="item in errorList" :key="item.id">
                    <div class="swiper box">
                        <topic
                            class="topic"
                            way="view"
                            v-bind="item"
                            :step="2"
                            :total="item.total_info"
                            :listLength="errorList.length"
                            @changeTopic="changeTopic"
                        ></topic>
                    </div>
                </van-swipe-item>
            </van-swipe>
        </div>
    </div>
</template>

<script>
import { userFailInfo } from "@/util/api";
import topic from "@/components/topic.vue";
import headerBox from "@/components/headerBox";
import { pageMixin } from "@/util/pageMixin";
export default {
    mixins: [pageMixin],
    components: {
        topic,
        headerBox,
    },
    data() {
        return {
            errorList: [],
        };
    },
    created() {
         this.indexJssdk();
        this.userFailInfo();
    },
    methods: {
        async userFailInfo() {
            try {
                const { data, code } = await userFailInfo({
                    answer_id: this.$route.query.answer_id,
                });
                if (code == 0) {
                    this.errorList = data.data.list;
                }
            } catch (error) {
                console.log(error);
            }
        },
        changeTopic(state) {
            state === "up"
                ? this.$refs.swiper.prev()
                : this.$refs.swiper.next();
        },
    },
};
</script>

<style lang="scss" scoped>
.content {
    min-height: calc(100vh - 44px);
    background: #f8f8f8;
}
.topic {
    margin-bottom: 30px;
}
.box {
    min-height: calc(100vh - 44px);
    background: #f8f8f8;
    padding: 15px;
    box-sizing: border-box;
    .swiper {
        min-height: 95vh;
        box-sizing: border-box;
    }
}
</style>
>
