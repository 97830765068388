<template>
    <div>
        <headerBox bgColor="#fff">打卡</headerBox>
        <div class="content">
            <div class="user-info">
                <div class="user-info-left">
                    <div>
                        <span class="user-info-left-name">{{
                            userInfo.name
                        }}</span>
                        <span class="user-info-left-line"></span>
                        <span class="user-info-left-phone">{{
                            userInfo.mobile
                        }}</span>
                    </div>
                    <div class="user-info-left-company">
                        <img
                            src="@/assets/address.png"
                            alt=""
                            class="address"
                        />
                        {{ userInfo.company_name }}
                    </div>
                </div>
                <div class="user-info-right" @click="show = true">
                    <!-- <img src="@/assets/query.png" alt="" />
                    打卡规则 -->
                </div>
            </div>
            <div class="middle">
                <div class="middle-type">
                    <van-row gutter="12">
                        <van-col
                            :span="24 / typeList.length"
                            v-for="(item, index) in typeList"
                            :key="index"
                        >
                            <div class="middle-type-box">
                                <div>
                                    {{ item.type_name }}
                                </div>
                                <div>
                                    {{ item.start_time }}-{{ item.end_time }}
                                </div>
                                <div v-if="item.is_status == 1">
                                    <img src="@/assets/already.png" alt="" />
                                    {{ item.clock_time }}已打卡
                                </div>
                                <div v-else>
                                    <!-- <img src="@/assets/not.png" alt="" /> -->
                                    未打卡
                                </div>
                            </div>
                        </van-col>
                    </van-row>
                </div>
                <div class="middle-content">
                    <div class="middle-content-time">{{ presentTime }}</div>
                    <div class="middle-content-punch">
                        <img
                            src="@/assets/punch.png"
                            @click="
                                $router.push({
                                    path: '/commitment',
                                    query: {
                                        clock_setting_id: punchId,
                                    },
                                })
                            "
                            alt=""
                            v-if="isPunch && !yetFlag"
                        />

                        <img
                            v-else-if="yetFlag"
                            src="@/assets/alreadyPunch.png"
                            alt=""
                            @click="yetFn"
                        />
                        <img src="@/assets/not-punch.png" v-else alt="" />
                    </div>
                </div>
            </div>
            <div class="footer" @click="watchRecordList">查看打卡记录</div>
        </div>
        <van-dialog title="打卡规则" v-model="show" :showConfirmButton="false">
            <div class="rules">
                <div></div>
                <van-button type="info" @click="show = false" block round
                    >确认</van-button
                >
            </div>
        </van-dialog>
    </div>
</template>

<script>
import headerBox from "@/components/headerBox";
import { getuserinfo, clockGetTodayClockList } from "@/util/api";
import { FormatTime, Time } from "@/util/com.js";
import { Toast } from "vant";
import { pageMixin } from "@/util/pageMixin";
export default {
    mixins: [pageMixin],
    components: {
        headerBox,
    },
    data() {
        return {
            userInfo: {},
            show: false,
            typeList: [],
            signFlag: false,
            isPunch: false,
            presentTime: FormatTime(),
            timer: "",
            currentTime: Time(),
            punchId: 0,
            yetFlag: false,
            yetFlagMsg: "",
        };
    },
    async created() {
        this.getUserInfo();
        this.indexJssdk();
        await this.clockGetTodayClockList();
        this.judge();
        this.timer = setInterval(() => {
            this.presentTime = FormatTime();
            this.currentTime = Time();
            this.judge();
        }, 1000);
    },
    destroyed() {
        clearInterval(this.timer);
    },
    methods: {
        judge() {
            this.yetFlag = false;
            this.typeList.forEach(item => {
                if (
                    this.CompareDate(this.currentTime, item.start_time) &&
                    this.CompareDate(item.end_time, this.currentTime) &&
                    !item.is_status
                ) {
                    this.punchId = item.id;
                    this.isPunch = true;
                }
                if (
                    this.CompareDate(this.currentTime, item.start_time) &&
                    this.CompareDate(item.end_time, this.currentTime) &&
                    item.is_status
                ) {
                    this.yetFlag = true;
                    this.yetFlagMsg = `${item.type_name}已打卡`;
                }
            });
        },
        async getUserInfo() {
            try {
                const { code, data } = await getuserinfo();
                if (code == 0) {
                    this.userInfo = data.data;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async clockGetTodayClockList() {
            const { data } = await clockGetTodayClockList();
            this.typeList = data;
        },
        CompareDate(t1, t2) {
            var date = new Date();
            var a = t1.split(":");
            var b = t2.split(":");
            return date.setHours(a[0], a[1]) >= date.setHours(b[0], b[1]);
        },
        watchRecordList() {
            this.$router.push({
                path: "/punch-record",
                name: "punch-record",
            });
        },
        yetFn() {
            Toast(this.yetFlagMsg);
        },
    },
};
</script>

<style scoped lang="scss">
.content {
    min-height: calc(100vh - 44px);
    background: #f8f8f8;
}
.user-info {
    padding: 20px 16px;
    border-radius: 10px;
    background: #fff;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    &-left {
        &-name {
            font-size: 20px;
            padding-right: 15px;
        }
        &-phone {
            color: #999999;
            padding-left: 15px;
        }
        &-line {
            display: inline-block;
            width: 1px;
            height: 10px;
            background: #e5e5e5;
        }
        &-company {
            padding-top: 10px;
            font-size: 14px;
            > img {
                width: 14px;
                height: 14px;
            }
        }
    }
    &-right {
        font-size: 12px;
        img {
            width: 12px;
            height: 12px;
        }
    }
}
.rules {
    padding: 25px;
}
.middle {
    margin-bottom: 40px;
    padding: 12px;
    background: #fff;
    border-radius: 10px;
    img {
        width: 166px;
        height: 166px;
    }
    &-content {
        &-punch {
            display: flex;
            justify-content: center;
        }
        &-time {
            text-align: center;
        }
    }
    &-type {
        margin-bottom: 50px;
        &-box {
            text-align: center;
            background: #f7f7f7;
            padding: 11px 1px 10px;
            div:first-child {
                font-weight: bold;
                font-size: 14px;
                margin-bottom: 6px;
            }
            div:nth-child(2) {
                font-weight: bold;
                font-size: 14px;
                margin-bottom: 6px;
            }
            div:nth-child(3) {
                font-size: 12px;
            }
            img {
                width: 10px;
                height: 10px;
            }
        }
    }
}
.footer {
    height: 45px;
    background: #ffffff;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.07);
    border-radius: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
