<template>
    <div>
        <headerBox bgColor="#fff" :isBack="false">注册</headerBox>
        <div class="all">
            <div class="content">
                <div class="form">
                    <div class="max">
                        <div class="label">姓名</div>
                        <div class="input">
                            <input
                                type="text"
                                placeholder="请输入您的姓名"
                                v-model="form.name"
                                @input="errorMsgFlag.name = false"
                                maxlength="10"
                            />
                        </div>
                        <div class="error" v-if="errorMsgFlag.name">
                            {{ msg.nameMsg }}
                        </div>
                    </div>
                    <div class="max">
                        <div class="label">联系方式</div>
                        <div class="input">
                            <input
                                type="text"
                                placeholder="请输入联系方式"
                                v-model="form.mobile"
                                @input="errorMsgFlag.mobile = false"
                                maxlength="11"
                            />
                        </div>
                        <div class="error" v-if="errorMsgFlag.mobile">
                            {{ msg.mobileMsg }}
                        </div>
                    </div>
                    <div class="max" @click="popupShow = true">
                        <div class="label">所属企业</div>
                        <div class="input company">
                            <span>{{ form.company_name }}</span>
                            <van-icon name="arrow" />
                        </div>
                        <div class="error" v-if="errorMsgFlag.company_id">
                            {{ msg.company_idMsg }}
                        </div>
                    </div>
                    <div class="max">
                        <div class="label">密码</div>
                        <div class="input">
                            <input
                                type="password"
                                placeholder="请填写密码"
                                v-model="form.password"
                                @input="changePassword"
                            />
                        </div>
                        <div class="error" v-if="errorMsgFlag.password">
                            {{ msg.passwordMsg }}
                        </div>
                    </div>
                    <div class="max">
                        <div class="label">确认密码</div>
                        <div class="input">
                            <input
                                type="password"
                                placeholder="请确认密码"
                                v-model="form.repeat_password"
                                @input="errorMsgFlag.repeat_password = false"
                            />
                        </div>
                        <div class="error" v-if="errorMsgFlag.repeat_password">
                            {{ msg.repeat_passwordMsg }}
                        </div>
                    </div>
                </div>
                <Btn @click="register" class="btn" :loading="btnLoading"
                    >注册</Btn
                >
            </div>
            <div class="register">
                已有账号？<span
                    class="register-page"
                    @click="
                        $router.push({
                            path: '/login',
                            query: { ...$route.query },
                        })
                    "
                    >去登录</span
                >
            </div>
        </div>
        <van-popup
            v-model="popupShow"
            position="bottom"
            :style="{ height: '400px' }"
        >
            <div class="search">
                <div class="search-box">
                    <input
                        type="text"
                        v-model="searchName"
                        placeholder="请输入企业名称查询"
                    />
                </div>
                <div>
                    <span @click="inquire(false)" v-if="searchFlag">查询</span>
                    <span @click="inquire(true)" v-else>取消</span>
                </div>
            </div>
            <van-picker
                @confirm="onConfirm"
                @cancel="popupShow = false"
                show-toolbar
                title="选择所属企业"
                :loading="loading"
                :columns="columns"
            />
        </van-popup>
    </div>
</template>

<script>
import Btn from "@/components/btn.vue";
import headerBox from "@/components/headerBox";
import { getCompanyList, register } from "@/util/api";
import { Toast } from "vant";
import { pageMixin } from "@/util/pageMixin";
export default {
    mixins: [pageMixin],
    components: {
        Btn,
        headerBox,
    },
    data() {
        return {
            errorMsgFlag: {
                name: false,
                mobile: false,
                company_id: false,
                password: false,
                repeat_password: false,
            },
            searchName: "",
            form: {
                name: "",
                mobile: "",
                company_id: "",
                password: "",
                repeat_password: "",
                company_name: "请选择所属企业",
            },
            msg: {
                nameMsg: "姓名填写错误，请填写正确的姓名",
                mobileMsg: "联系方式填写错误，请填写11位电话号码",
                company_idMsg: "所属企业不能为空，请选择所属企业",
                passwordMsg: "最少输入8位，可输入英文、符号、数字",
                repeat_passwordMsg: "重复密码不能为空，请输入重复密码",
            },
            popupShow: false,
            columns: [],
            searchFlag: true,
            loading: true,
            btnLoading: false,
        };
    },
    watch: {
        popupShow(newValue) {
            if (!newValue) {
                this.searchFlag = true;
                this.searchName = "";
                this.getCompanyList();
            }
        },
        searchName(newValue) {
            if (!newValue && !this.searchFlag) {
                this.searchFlag = true;
                this.getCompanyList();
            }
        },
        "form.company_id": {
            handler(newV) {
                if (newV) {
                    this.errorMsgFlag.company_id = false;
                }
            },
        },
    },
    created() {
        this.getCompanyList();
        this.indexJssdk();
    },
    methods: {
        changePassword() {
            this.form.password = this.form.password.replace(
                /[\u4E00-\u9FA5]/g,
                ""
            );
            this.errorMsgFlag.password = false;
        },
        inquire(searchFlag) {
            if (!searchFlag && !this.searchName) return Toast("请输入企业名称");
            this.searchFlag = searchFlag;
            this.searchName = searchFlag ? "" : this.searchName;
            this.getCompanyList(this.searchName);
        },
        async getCompanyList(keyWord) {
            try {
                this.loading = true;
                const { code, data } = await getCompanyList({
                    company_name: keyWord,
                });
                if (code == 0) {
                    this.columns = data.map(item => {
                        return {
                            text: item.company_name,
                            ...item,
                        };
                    });
                    if (data.length == 0) {
                        this.columns.push({
                            text: "暂无企业",
                            disabled: true,
                        });
                    }
                }
            } catch (error) {
                console.log(error);
            } finally {
                this.loading = false;
            }
        },
        onConfirm(value) {
            if (value.text === "暂无企业" && value.disabled) {
                return Toast("请选择所属企业");
            }
            this.form.company_name = value.company_name;
            this.form.company_id = value.id;
            this.popupShow = false;
        },
        // 规则校验
        verify() {
            let form = JSON.parse(JSON.stringify(this.form));
            delete form.company_name;
            this.msg.repeat_passwordMsg = "重复密码不能为空，请输入重复密码";
            if (!Object.values(form).filter(Boolean).length) {
                Object.keys(this.errorMsgFlag).forEach(item => {
                    this.errorMsgFlag[item] = true;
                });
                return false;
            }
            if (!/^[a-zA-Z\u4e00-\u9fa5]+$/.test(this.form.name)) {
                this.errorMsgFlag.name = true;
                return false;
            }
            if (!/1[3-9]\d{9}/.test(this.form.mobile)) {
                this.errorMsgFlag.mobile = true;
                return false;
            }
            if (!this.form.company_id) {
                this.errorMsgFlag.company_id = true;
                return false;
            }

            if (this.form.password.length < 8) {
                this.errorMsgFlag.password = true;
                return false;
            }
            if (!this.form.repeat_password.length) {
                this.errorMsgFlag.repeat_password = true;
                return false;
            }
            if (this.form.password !== this.form.repeat_password) {
                this.msg.repeat_passwordMsg = "新密码不一致，请确认后重新输入";
                this.errorMsgFlag.repeat_password = true;
                return false;
            }
            return true;
        },
        async register() {
            if (this.verify()) {
                try {
                    this.btnLoading = true;
                    const { code, msg } = await register(this.form);
                    if (code == 0) {
                        Toast("注册成功！");
                        this.$router.push("/login");
                    } else {
                        Toast(msg);
                    }
                } catch (error) {
                    console.log(error);
                } finally {
                    this.btnLoading = false;
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.all {
    background: #fff;
    padding-bottom: 20px;
    min-height: calc(100vh - 44px);
}
.register {
    padding-top: 25px;
    text-align: center;
    color: #ccc;
    &-page {
        color: var(--color-main);
    }
}
.form {
    padding: 0px 25px 0;
    .max {
        margin-bottom: 17px;
    }
    .label {
        font-size: 18px;
        color: #333333;
    }
    .input {
        padding: 12px 0;
        height: 45px;
        border-bottom: 1px solid #eaeaea;
        span {
            font-size: 14px;
        }
        input {
            width: 100%;
        }
    }
    .error {
        margin-top: 8px;
        color: #e62f37;
        font-size: 12px;
    }
}
.search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    padding: 15px;
    box-sizing: border-box;
    &-box {
        width: 90%;
        height: 36px;
        background: #f8f8f8;
        border-radius: 18px;
        display: flex;
        align-items: center;
        padding: 0 15px;
        box-sizing: border-box;
        input {
            width: 100%;
        }
    }
}
.company {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
::v-deep .van-picker__toolbar {
    background: #f7f7f7;
}

::v-deep .van-picker__confirm {
    color: #000;
}
</style>
>
