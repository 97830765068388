<template>
    <div>
        <div class="header" ref="header">
            <headerBox :bgColor="barColor">积分记录</headerBox>
            <div class="header-content">
                <div class="header-box">
                    <div class="header-box-logo">
                        <img :src="details.logo" alt="" />
                    </div>
                    <div>
                        <div class="gray">
                            总积分<span class="header-box-score">{{
                                details.integral
                            }}</span>
                        </div>
                        <div class="gray font-14">
                            {{ details.company_name }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="contents">
            <div class="contents-text">积分明细</div>
            <div class="contents-box">
                <van-list
                    v-model="loading"
                    :finished="finished"
                    finished-text="没有更多了"
                    @load="onLoad"
                    ><div
                        class="contents-box-list"
                        v-for="item in scoreList"
                        :key="item.id"
                    >
                        <div>
                            <div>{{ item.answer_title }}</div>
                            <div class="contents-box-list-time gray">
                                {{ item.created_at.substr(0, 10) }}
                            </div>
                        </div>
                        <div class="contents-box-list-score">
                            +{{ item.num }}
                        </div>
                    </div>
                </van-list>
            </div>
        </div>
    </div>
</template>

<script>
import { integralList } from "@/util/api";
import headerBox from "@/components/headerBox";
import { getScrollTop } from "@/util/com.js";
import { pageMixin } from "@/util/pageMixin";
export default {
    mixins: [pageMixin],
    components: {
        headerBox,
    },
    data() {
        return {
            loading: false,
            finished: false,
            pageInfo: {
                page: 1,
                page_size: 10,
            },
            scoreList: [], //积分列表
            details: {}, // 详情
            barColor: "",
        };
    },
    created() {},
    mounted() {
        this.indexJssdk();
        window.onscroll = () => {
            this.barColor = getScrollTop() ? "#fff" : "";
        };
    },
    methods: {
        async integralList() {
            try {
                const { code, data } = await integralList(this.pageInfo);
                this.loading = false;
                if (code == 0) {
                    if (this.pageInfo.page == 1) {
                        this.scoreList = data.list;
                        this.details = data.user;
                    } else {
                        this.scoreList.push(...data.list);
                    }
                }
                if (data.list.length < 10) {
                    this.finished = true;
                }
                this.pageInfo.page++;
            } catch (error) {
                console.log(error);
            }
        },
        onLoad() {
            this.integralList();
        },
    },
};
</script>

<style lang="scss" scoped>
.gray {
    color: var(--color-gray);
}
.header {
    height: 144px;
    background: linear-gradient(0deg, #f8f8f8 0%, #fbce57 100%);
    &-content {
        position: relative;
        z-index: 9;
        width: 100%;
        padding: 15px;
        box-sizing: border-box;
    }
    &-box {
        display: flex;
        align-items: center;
        height: 105px;
        border-radius: 15px;
        background: #fff;
        padding: 15px;
        box-sizing: border-box;
        &-score {
            color: var(--color-whole);
            padding-left: 5px;
            font-size: 24px;
        }
        &-logo {
            width: 70px;
            height: 40px;
            margin-right: 10px;
            img {
                height: 100%;
            }
        }
    }
}
.contents {
    padding: 0 15px;
    background: #f8f8f8;
    &-text {
        font-size: 15px;
        padding: 25px 13px 15px;
        font-weight: bold;
        color: #333333;
    }
    &-box {
        width: 100%;
        background: #fff;
        min-height: calc(100vh - 205px);
        border-radius: 15px 15px 0px 0px;
        &-list {
            margin: 0 15px;
            padding: 15px 0;
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #e5e5e5;
            &-time {
                padding-top: 12px;
                font-size: 14px;
            }
            &-score {
                padding-top: 12px;
                color: var(--color-whole);
                font-weight: bold;
            }
        }
    }
}
</style>
