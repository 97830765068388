<template>
    <div>
        <headerBox bgColor="#fff">修改密码</headerBox>
        <div class="content">
            <div class="form">
                <div class="form">
                    <div class="max">
                        <div class="label">原密码</div>
                        <div class="input">
                            <input
                                :type="type.password"
                                placeholder="请输入原密码"
                                v-model="form.old_password"
                                @input="oldPassWordShow = false"
                            />
                            <div class="eye" @click="changePwd('password')">
                                <img
                                    v-if="type.password == 'text'"
                                    src="@/assets/open-eye.png"
                                    alt=""
                                />
                                <img
                                    v-else
                                    src="@/assets/close-eye.png"
                                    alt=""
                                />
                            </div>
                        </div>
                        <div class="error" v-if="oldPassWordShow">
                            {{
                                requiredFlag
                                    ? requiredMsg.oldPassword
                                    : msg.oldPassword
                            }}
                        </div>
                    </div>
                    <div class="max">
                        <div class="label">新密码</div>
                        <div class="input">
                            <input
                                :type="type.newPassword"
                                placeholder="请输入新密码"
                                v-model="form.password"
                                @input="newPasswordShow = false"
                            />
                            <div class="eye" @click="changePwd('newPassword')">
                                <img
                                    v-if="type.newPassword == 'text'"
                                    src="@/assets/open-eye.png"
                                    alt=""
                                />
                                <img
                                    v-else
                                    src="@/assets/close-eye.png"
                                    alt=""
                                />
                            </div>
                        </div>
                        <div class="error" v-if="newPasswordShow">
                            {{
                                requiredFlag
                                    ? requiredMsg.newPassword
                                    : msg.newPassword
                            }}
                        </div>
                    </div>
                    <div class="max">
                        <div class="label">确认新密码</div>
                        <div class="input">
                            <input
                                @input="rePasswordShow = false"
                                :type="type.rePassword"
                                placeholder="请再次输入新密码"
                                v-model="form.repeat_password"
                            />
                            <div class="eye" @click="changePwd('rePassword')">
                                <img
                                    v-if="type.rePassword == 'text'"
                                    src="@/assets/open-eye.png"
                                    alt=""
                                />
                                <img
                                    v-else
                                    src="@/assets/close-eye.png"
                                    alt=""
                                />
                            </div>
                        </div>
                        <div class="error" v-if="rePasswordShow">
                            {{
                                requiredFlag
                                    ? requiredMsg.rePassword
                                    : msg.rePassword
                            }}
                        </div>
                    </div>
                </div>
                <Btn :loading="loading" @click="submit">提交</Btn>
            </div>
        </div>
    </div>
</template>

<script>
import Btn from "@/components/btn.vue";
import { editUserInfo } from "@/util/api";
import { Toast } from "vant";
import headerBox from "@/components/headerBox";

import { pageMixin } from "@/util/pageMixin";
export default {
    mixins: [pageMixin],
    components: {
        Btn,
        headerBox,
    },
    data() {
        return {
            oldPassWordShow: false,
            newPasswordShow: false,
            rePasswordShow: false,
            form: {
                old_password: "",
                password: "",
                repeat_password: "",
            },
            type: {
                password: "password",
                newPassword: "password",
                rePassword: "password",
            },
            loading: false,
            msg: {
                newPassword: "请输入新密码",
                oldPassword: "旧密码登录密码不一致，请重新输入",
                rePassword: "新密码不一致，请确认后重新输入",
            },
            requiredMsg: {
                newPassword: "请输入新密码",
                oldPassword: "请输入原密码",
                rePassword: "请再次输入新密码",
            },
            requiredFlag: true,
        };
    },
    created() {
         this.indexJssdk();
    },
    methods: {
        // 提交
        async submit() {
            try {
                this.requiredFlag = true;
                if (!Object.values(this.form).filter(Boolean).length) {
                    this.oldPassWordShow = true;
                    this.newPasswordShow = true;
                    this.rePasswordShow = true;
                    return;
                }
                if (!this.form.old_password) {
                    return (this.oldPassWordShow = true);
                }
                if (!this.form.password) {
                    this.requiredMsg.newPassword = "请输入新密码";
                    return (this.newPasswordShow = true);
                }
                if (/.*[\u4e00-\u9fa5]+.*$/.test(this.form.password)) {
                    this.requiredMsg.newPassword = "新密码格式错误，不能为中文";
                    return (this.newPasswordShow = true);
                }
                if (!this.form.repeat_password) {
                    return (this.rePasswordShow = true);
                }
                this.requiredFlag = false;
                if (this.form.password != this.form.repeat_password)
                    return (this.rePasswordShow = true);
                this.loading = true;
                const { code, msg } = await editUserInfo(this.form);
                if (code == 0) {
                    localStorage.removeItem("UTOKEN");
                    Toast("修改成功，请重新登录");
                    this.$router.replace("/login");
                } else if (code == 102000) {
                    this.oldPassWordShow = true;
                } else {
                    Toast(msg);
                }
            } catch (error) {
                console.log(error);
            } finally {
                this.loading = false;
            }
        },
        // 切换密码显示
        changePwd(type) {
            switch (this.type[type]) {
                case "text":
                    this.type[type] = "password";
                    break;
                case "password":
                    this.type[type] = "text";
                    break;
                default:
                    break;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.content {
    min-height: calc(100vh - 44px);
    background: #f8f8f8;
}
.form {
    padding-top: 5px;
    .max {
        margin-bottom: 17px;
    }
    .label {
        font-size: 16px;

        color: #333333;
    }
    .input {
        padding: 12px 0;
        border-bottom: 1px solid #eaeaea;
        display: flex;
        input {
            width: 95%;
        }
    }
    .error {
        margin-top: 8px;
        color: #e62f37;
        font-size: 12px;
    }
    .eye {
        width: 23px;
        height: 16px;
        img {
            width: 100%;
            height: 100%;
        }
    }
}
</style>
