<template>
  <div class="index">
    <div class="header d_flex">
      <div class="logo">
        <img :src="company.company && company.company.logo" />
      </div>
    </div>
    <div class="swiper-box" :style="swiperHeightStyle">
      <van-swipe :height="swiperHeight">
        <van-swipe-item v-for="(banner, index) in banners" :key="index">
          <img :style="swiperHeightStyle" :src="banner.img" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <tabs :list="tabs" @changeTab="changeTab"></tabs>
    <statusTab
      :list="statusTab"
      ref="statusTab"
      @changeStatus="changeStatus"
    ></statusTab>
    <div class="list">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div
          class="item"
          v-for="(item, index) in list"
          :key="index"
          @click="toDetail(item.id)"
        >
          <div class="img">
            <div class="play" v-if="query.type == 1">
              <img src="../../assets/play.png" />
            </div>
            <img
              :src="item.answer_cover_img || require('@/assets/answer.png')"
            />
          </div>
          <div class="title">{{ item.title }}</div>
          <div class="bottom">
            <div class="left">
              <div class="left-max">
                <div class="left-max-label">发布时间:</div>
                <div class="date">
                  {{ item.created_at.substr(0, 10) }}
                </div>
                <div class="participation" v-if="item.is_participate == 1">
                  已参与
                </div>
              </div>
            </div>
            <div class="right">
              <span class="text">开始答题</span>
            </div>
          </div>
        </div>
      </van-list>
    </div>
    <div class="suspension" @click="$router.push('/punch')">
      <img src="@/assets/punchEveryday.png" alt="" />
    </div>
  </div>
</template>

<script>
import { indexBanner, answerList, indexConfig } from "../../util/api";
import { Toast } from "vant";
import tabs from "../../components/tabs";
import statusTab from "../../components/statusTab";
import { pageMixin } from "@/util/pageMixin";

export default {
  name: "Index",
  components: { tabs, statusTab },
  mixins: [pageMixin],
  data() {
    return {
      swiperHeight: "180",
      tabs: [
        {
          name: "问卷答题",
          index: 2,
        },
        {
          name: "视频答题",
          index: 1,
        },
      ],
      statusTab: [
        {
          name: "全部",
          index: 0,
        },
        {
          name: "未参与",
          index: 2,
        },
        {
          name: "已参与",
          index: 1,
        },
      ],
      images: [],
      show: false,
      src: "",
      isLogin: "",
      query: {
        type: 2,
        page: 1,
        page_size: 10,
        status: 0,
      },
      list: [],
      banners: [],
      finished: false,
      loading: false,
      company: {},
    };
  },
  computed: {
    swiperHeightStyle() {
      return { height: this.swiperHeight + "px" };
    },
  },
  mounted() {
    this.indexJssdk();
    this.getBanner();
    this.indexConfig();
    this.$nextTick(() => {
      this.swiperHeight = document.querySelector(".swiper-box").offsetWidth / 2;
      window.addEventListener("resize", () => {
        this.swiperHeight =
          document.querySelector(".swiper-box").offsetWidth / 2;
      });
    });
    // 分享进来的链接(如果是登录状态,直接进入分享详情)
    if (this.$route.query.answer_type && this.$route.query.id) {
      this.$router.push({
        path: this.$route.query.answer_type == 1 ? "/video" : "/answerDetail",
        query: { answer_id: this.$route.query.id },
      });
    }
  },
  methods: {
    async indexConfig() {
      try {
        const { code, data } = await indexConfig();
        if (code == 0) {
          this.company = data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    toDetail(answer_id) {
      this.$router.push({
        path: this.query.type == 1 ? "/video" : "/answerDetail",
        query: { answer_id },
      });
    },
    // 切换问卷和视频
    changeTab(index) {
      if (this.query.type == index) return;
      this.$refs.statusTab.reset();
      this.query.type = index;
      this.query.page = 1;
      this.query.status = 0;
      this.loading = true;
      this.finished = false;
      this.list = [];
      if (this.loading) {
        this.onLoad();
      }
    },
    // 切换状态
    changeStatus(index) {
      if (this.query.status == index) return;
      this.query.status = index;
      this.query.page = 1;
      this.loading = true;
      this.finished = false;
      this.list = [];
      if (this.loading) {
        this.onLoad();
      }
    },
    async getBanner() {
      try {
        const { code, data } = await indexBanner();
        if (code == 0) {
          this.banners = data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    onLoad() {
      this.getList();
    },
    getList() {
      answerList(this.query).then((res) => {
        this.loading = false;
        if (res.code == 0) {
          if (this.query.page == 1) {
            this.list = res.data.list;
          } else {
            this.list.push(...res.data.list);
          }
          if (res.data.list.length < 10) {
            this.finished = true;
          }
          this.query.page++;
        } else {
          Toast(res.msg);
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.index {
  background-color: #f8f8f8;
  min-height: 100vh;
  padding-bottom: 40px;
}
.header {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  .logo {
    margin-left: 10px;
    width: 64px;
    text-align: center;
    img {
      // width: 100%;
      height: 40px;
    }
  }
  .tit {
    flex: 1;
    text-align: center;
    padding-right: 70px;
    box-sizing: border-box;
    font-size: 15px;
  }
}
.swiper-box {
  width: calc(100vw - 30px);
  margin: 10px auto;
  border-radius: 10px;
  img {
    object-fit: cover;
    width: calc(100vw - 30px);
    width: 100%;
    border-radius: 10px;
  }
}
.item {
  margin: 15px 15px 0;
  background-color: #ffffff;
  border-radius: 5px;
  overflow: hidden;
  z-index: -1;
}
.img {
  position: relative;
  width: calc(100vw - 30px);
  height: 172.5px;
}
.img img {
  width: calc(100vw - 30px);
  height: 172.5px;
  object-fit: cover;
}
.play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.play img {
  width: 36px;
  height: 36px;
}
.title {
  margin: 10px 10px 0;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}
.bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 10px 0;
  padding-bottom: 15px;
}
.date {
  margin-left: 5px;
  font-size: 12px;
  font-weight: bold;
  color: #999999;
  z-index: 1;
}
.time {
  font-size: 12px;
  font-weight: bold;
  color: #999999;
  margin-left: 5px;
  z-index: 1;
}
.participation {
  color: #dfa000;
  border: 1px solid #dfa000;
  border-radius: 3px;
  padding: 0 2px;
  margin-left: 5px;
}
.bottom-line {
  position: relative;
  margin-top: -6px;
  height: 8px;
  background: #f8eeee;
  z-index: -2;
}
.right {
  position: relative;
  width: 80px;
  height: 32px;
  background: var(--color-main);
  border-radius: 16px;
}
.right .icon-fenxiang1 {
  font-size: 28px;
  background: linear-gradient(0deg, #eb4950, #ffd8da);
  -webkit-background-clip: text;
  color: transparent;
}
.right .text {
  position: relative;
  top: 5px;
  left: 6px;
  color: #fef4f4;
  font-size: 16px;
}
.share {
  position: fixed;
  left: 11vw;
  top: -70vw;
  width: 116px;
  height: 99px;
}
.share img {
  width: 100%;
  height: 100%;
}
.van-popup {
  overflow-y: unset !important;
}
.equal {
  position: relative;
  top: -3px;
}
.left-max {
  font-size: 12px;
  color: #999999;
  display: flex;
  align-items: center;
}
.join-status {
  padding: 0 15px;
}
.suspension {
  width: 65px;
  height: 70px;
  position: fixed;
  right: 10px;
  bottom: 300px;
  animation:  bounce-up 1s linear 1.1s infinite;
  img {
    width: 100%;
    height: 100%;
  }
}



@keyframes bounce-up {
  25% {
    transform: translateY(10px);
  }
  50%,
  100% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(-10px);
  }
}
</style>
