<template>
  <div>
    <headerBox v-if="!signFlag" bgColor="#fff">承诺书</headerBox>
    <div v-if="!signFlag" class="book" ref="contentBook">
      <div class="content-books ql-editor">
        <div v-html="commitment" class="content-text"></div>
        <div class="footer">
          <div>
            <div class="footer-people">
              <div class="footer-text">承诺人:</div>
              <div v-show="signImg" class="footer-img-box">
                <img class="footer-img" :src="signImg" alt="" @click="goSign" />
              </div>
              <div class="footer-sign" v-show="!signImg" @click="goSign">
                点击签名
              </div>
            </div>
            <div>
              <div class="footer-text">日期:</div>
              <div>{{ date }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content" v-if="!signFlag">
      <van-button type="info" block round @click="punchCard" :loading="loading"
        >打卡</van-button
      >
    </div>
    <sign v-if="signFlag" @signImgPath="signImgPath"></sign>
  </div>
</template>

<script>
import sign from "@/views/commitment/component/sign";
import html2canvas from "@/util/html2canvas";
import headerBox from "@/components/headerBox";
import {
  clockGetCommitmentInfo,
  clockSubmitClock,
  uploadGetSign,
} from "@/util/api";
import { base64ImgtoFile, DateYY } from "@/util/com.js";
import { ossRequest } from "@/util/ossRequest.js";
import { Toast } from "vant";
import { pageMixin } from "@/util/pageMixin";
export default {
  mixins: [pageMixin],
  components: {
    sign,
    headerBox,
  },
  data() {
    return {
      signFlag: false,
      commitment: "",
      signImg: "",
      date: DateYY(),
      loading: false,
    };
  },
  created() {
    this.clockGetCommitmentInfo();
    this.indexJssdk();
  },
  methods: {
    async clockGetCommitmentInfo() {
      try {
        const { data } = await clockGetCommitmentInfo();
        this.commitment = data.commitment;
      } catch (error) {
        console.log(error);
      }
    },
    punchCard() {
      if (!this.signImg) return Toast("请先签名！");
      this.loading = true;
      (window.html2canvas || html2canvas)(this.$refs.contentBook, {
        allowTaint: true,
        backgroundColor: "#ffffff",
        useCORS: true,
        scrollY: 0,
        scrollX: 0,
      }).then((canvas) => {
        var file = base64ImgtoFile(canvas.toDataURL());
        // 文件对象
        uploadGetSign().then((res) => {
          ossRequest(res.data, file).then(async (res) => {
            const { code, msg } = await clockSubmitClock({
              clock_setting_id: this.$route.query.clock_setting_id,
              img: res,
            });
            if (code == 0) {
              Toast("打卡成功！");
              this.$router.replace("/punch");
            } else {
              Toast(msg);
            }
          });
        });
        this.loading = false;
      });
    },
    goSign() {
      this.signFlag = true;
    },
    signImgPath(path) {
      this.signFlag = false;
      this.signImg = path;
    },

    /**
     * @description: 图片压缩
     */
    compressImg(file) {
      let disposeFile = file;
      if (Object.prototype.toString.call(file) === "[object Blob]") {
        disposeFile = new File([file], file.name, { type: file.type });
      }
      const read = new FileReader(),
        _that = this;
      const fileSize = parseFloat(
        parseInt(disposeFile["size"]) / 1024 / 1024
      ).toFixed(2);
      // 小于1m不压缩
      if (fileSize < 1) return file;
      read.readAsDataURL(disposeFile);
      return new Promise((resolve, reject) => {
        try {
          read.onload = (e) => {
            const img = new Image();
            img.src = e.target.result;
            img.onload = function () {
              let w = Math.floor(this.width / 2),
                h = Math.floor(this.height / 2);
              const canvas = document.createElement("canvas");
              const ctx = canvas.getContext("2d");
              let base64;
              canvas.setAttribute("width", w);
              canvas.setAttribute("height", h);
              ctx.drawImage(this, 0, 0, w, h);
              console.log(w, h);
              if (fileSize < 1) {
                // 如果图片小于一兆 那么不执行压缩操作
                base64 = canvas.toDataURL(disposeFile["type"], 1);
              } else if (fileSize > 1 && fileSize < 2) {
                // 如果图片大于1M并且小于2M 那么压缩0.5
                base64 = canvas.toDataURL(disposeFile["type"], 0.5);
              } else {
                // 如果图片超过2m 那么压缩0.2
                base64 = canvas.toDataURL(disposeFile["type"], 0.2);
              }
              resolve(_that.dataURLtoFile(base64, disposeFile.name));
            };
          };
        } catch (error) {
          reject(disposeFile);
        }
      });
    },
    /**
     * @description: 将base64编码转回file文件
     */
    dataURLtoFile(dataurl, fileName) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], fileName, {
        type: mime,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.content-text {
  font-size: 14px;
  font-family: PingFang-SC-Bold;
  // /deep/.ql-size-small {
  //   font-size: 12px !important;
  // }
  // /deep/.ql-size-large {
  //   font-size: 20px !important;
  // }
  // /deep/.ql-size-huge {
  //   font-size: 30px !important;
  // }
}
.book {
  padding: 25px;
  box-sizing: border-box;
  width: 100%;
  // height: 512px;
  padding-bottom: 150px;
  background-image: url("../../assets/sign-book.png");
  background-size: 100% 100%;
}
.footer {
  display: flex;
  justify-content: flex-end;
  font-family: PingFang-SC-Bold;
  &-img-box {
    border: 1px solid #eeeeee;
    width: 72px;
    height: 37px;
    font-size: PingFang-SC-Bold;
  }
  &-people {
    margin-bottom: 15px;
    font-family: PingFang-SC-Bold;
  }
  &-text {
    color: #999;
    margin-bottom: 5px;
    font-family: PingFang-SC-Bold;
  }
  &-sign {
    padding: 13px 15px;
    background: #e5e5e5;
    color: #008be5;
    font-family: PingFang-SC-Bold;
  }
  &-img {
    width: 70px;
    height: 35px;
    // transform: rotate(-90deg);
  }
}
p,
u,
em,
s,
strong {
  font-family: PingFang-SC-Bold;
}
</style>
