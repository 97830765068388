<template>
  <div>
    <headerBox bgColor="#fff">详情</headerBox>
    <div class="page-box">
      <div class="title">{{ details.title }}</div>
      <div class="describe">
        <div>
          <span style="color: #999999">发布者:</span
          >{{ details.government && details.government.government_name }}
        </div>
        <div>{{ details.updated_at && details.updated_at.substr(0, 10) }}</div>
      </div>
      <div class="ql-editor">
        <div
          class="content"
          v-html="
            details.content &&
            details.content.replace(/\<img/gi, `<img style=width:100%`)
          "
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { getNewsDetail } from "@/util/api.js";
import headerBox from "@/components/headerBox";

export default {
  components: {
    headerBox,
  },
  data() {
    return {
      details: {},
    };
  },
  created() {
    this.getNewsDetail();
  },
  methods: {
    async getNewsDetail() {
      try {
        const { data, code } = await getNewsDetail({
          news_id: this.$route.query.news_id,
        });
        if (code === 0) {
          this.details = data;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page-box {
  padding: 15px 13px;
  min-height: calc(100vh - 44px);
  background: #f8f8f8;
}
.title {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 15px;
}
.describe {
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>