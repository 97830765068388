<template>
    <div class="content">
        <div class="sign">
            <div class="text">安全承诺前请在下方签名并提交</div>
            <div class="sign-border">
                <vue-ele-sign
                    ref="VueEleSign"
                    :direction="direction"
                    :pen="pen"
                    :bgImg="bgImg"
                    :imgType="imgType"
                    :readOnly="readOnly"
                    :vsConsole="true"
                />
            </div>
            <div class="btns">
                <div class="btn-b" @click="cancel">重写</div>
                <div class="btn" @click="submitSign">确定</div>
            </div>
        </div>
    </div>
</template>

<script>
import VueEleSign from "vue-ele-sign";
import { Toast } from "vant";
import { uploadGetSign } from "@/util/api";
import { ossRequest } from "@/util/ossRequest.js";
export default {
    components: {
        VueEleSign,
    },
    name: "sign",
    props: {
        show: Boolean,
        src: String,
    },
    data() {
        return {
            direction: "",
            pen: "default",
            imgType: "png",
            readOnly: false,
            bgImg: "",
            is_set: 0,
            currentIndex: 0,
        };
    },
    methods: {
        cancel() {
            this.$refs.VueEleSign.clear();
        },
        back() {
            this.$emit("scancel", { index: this.currentIndex });
        },
        setIndex(index) {
            this.currentIndex = index;
        },
        submitSign() {
            let isEmpty = this.$refs.VueEleSign.isEmpty();
            if (isEmpty) {
                Toast("请书写签名");
            } else {
                this.$refs.VueEleSign.getImg().then(res => {
                    this.$emit("signImgPath", res);
                });
            }
        },
        async uploadGetSign(file) {
            const res = await uploadGetSign();
            ossRequest(res.data, file).then(res => {
                this.$emit("signImgPath", res);
            });
        },
    },
    watch: {
        index(val) {
            console.log(val);
        },
    },
};
</script>

<style scoped>
.sign {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: #ffffff;
}
.sign-border {
    border: 1px solid #999;
    transform: translateY(50%);
}
.btns {
    position: fixed;
    bottom: 20px;
    left: 0;
    padding: 0 15px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    z-index: 99;
}
.btn-dft {
    height: 38px;
    line-height: 38px;
    width: 100px;
    text-align: center;
    font-size: 16px;
    color: #ffffff;
    background: #999999;
    border-radius: 38px;
}
.btn-b {
    height: 38px;
    line-height: 38px;
    width: 100px;
    text-align: center;
    font-size: 16px;
    color: #ffffff;
    background: #4261e3;
    border-radius: 38px;
}
.btn {
    height: 38px;
    line-height: 38px;
    width: 100px;
    text-align: center;
    font-size: 16px;
    color: #ffffff;
    background: linear-gradient(
        94deg,
        rgba(255, 74, 63, 0.89) 0%,
        #f92828 99.16%
    );
    box-shadow: 0 0 0.16rem rgb(250 45 44 / 60%);
    border-radius: 38px;
}
.img {
    width: 100px;
    height: 100px;
    margin: 0 auto;
}
.img img {
    width: 100%;
    height: 100px;
}
.text {
    padding: 30px;
    text-align: center;
    color: #4261e3;
}
</style>
<style>
canvas {
    width: calc(100vw - 2px) !important;
    height: 40vh !important;
    background: #fff;
}
</style>
