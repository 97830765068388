<template>
    <div>
        <div class="header">
            <div>积分排行榜</div>
            <div class="header-img">
                <div class="header-img-ranking">
                    <img src="@/assets/ranking.png" alt="" />
                </div>
                <div class="header-img-king">
                    <img src="@/assets/king.png" alt="" />
                </div>
            </div>
        </div>
        <div v-if="loading"><van-loading class="loading" /></div>
        <div v-else class="content">
            <div class="my-box">
                <!--排名 -->
                <div class="current">
                    <div class="my">
                        我的成绩
                        <span class="my-not" v-if="user.my_rank == 0"
                            >(未上榜)</span
                        >
                    </div>
                    <div class="list my-list">
                        <div class="list-left">
                            <span class="list-rank">
                                <img
                                    v-if="user.my_rank == 1"
                                    src="@/assets/one.png"
                                    alt=""
                                />
                                <img
                                    v-else-if="user.my_rank == 2"
                                    src="@/assets/two.png"
                                    alt=""
                                />
                                <img
                                    v-else-if="user.my_rank == 3"
                                    src="@/assets/three.png"
                                    alt=""
                                />
                                <img
                                    v-else-if="user.my_rank == 0"
                                    src="@/assets/not-ranking.png"
                                    alt=""
                                    class="not-ranking"
                                />
                                <span class="list-num" v-else>{{
                                    user.my_rank
                                }}</span>
                            </span>
                            <span class="list-name">{{ user.name }}</span>
                        </div>
                        <div class="list-name">{{ user.integral }}积分</div>
                    </div>
                </div>
            </div>
            <div class="box">
                <!--排名 -->
                <div class="ranking" v-for="item in rankList" :key="item.id">
                    <div class="list">
                        <div class="list-left">
                            <span class="list-rank">
                                <img
                                    v-if="item.rank == 1"
                                    src="@/assets/one.png"
                                    alt=""
                                />
                                <img
                                    v-else-if="item.rank == 2"
                                    src="@/assets/two.png"
                                    alt=""
                                />
                                <img
                                    v-else-if="item.rank == 3"
                                    src="@/assets/three.png"
                                    alt=""
                                />
                                <span v-else class="list-num">{{
                                    item.rank
                                }}</span>
                            </span>
                            <span
                                class="list-rankingName"
                                :class="[user.id == item.id ? 'list-name' : '']"
                                >{{ item.name }}</span
                            >
                        </div>
                        <div class="list-name">{{ item.integral }}积分</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { integralTopList } from "@/util/api";
import { pageMixin } from "@/util/pageMixin";
export default {
    mixins: [pageMixin],
    data() {
        return {
            user: {},
            rankList: [],
            loading: true,
        };
    },
    created() {
        this.integralTopList();
         this.indexJssdk();
    },
    methods: {
        async integralTopList() {
            try {
                const { code, data } = await integralTopList();
                if (code == 0) {
                    this.rankList = data.list;
                    this.user = data.user;
                }
            } catch (error) {
                console.log(error);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.content {
    background: #f8f8f8;
}
.header {
    padding-top: 10px;
    box-sizing: border-box;
    height: 143px;
    text-align: center;
    font-weight: 500;
    color: #000000;
    background: linear-gradient(0deg, #f8f8f8 0%, #fbce57 100%);
    &-img {
        display: flex;
        justify-content: space-between;
        padding: 15px;
        &-ranking {
            width: 133px;
            height: 59px;
            transform: translate(10px, 40px);
            img {
                width: 100%;
                height: 100%;
            }
        }
        &-king {
            width: 175px;
            height: 107px;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}
.content {
    font-size: 14px;
    .box {
        width: 100%;
        background: #fff;
        min-height: calc(100vh - 270px);
        transform: translateY(-10px);
        padding: 15px;
        box-sizing: border-box;
    }
}
.my-box {
    padding: 15px;
    box-sizing: border-box;
    transform: translateY(-15px);
    height: 78px;
    background: #fff;
    border-radius: 15px 15px 0px 0px;
    .my {
        margin-bottom: 8px;
        &-not {
            color: #999999;
        }
    }
}
.list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-left {
        display: flex;
        align-items: center;
    }
    &-num {
        display: inline-block;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: var(--color-whole);
        text-align: center;
        line-height: 30px;
        color: #fff;
    }
    &-rank {
        transform: scale(0.8);
        img {
            width: 30px;
            height: 37px;
            vertical-align: middle;
        }
    }
    &-name {
        min-width: 50px;
        padding-left: 8px;
        color: var(--color-whole);
    }
}
.ranking {
    .list {
        padding: 15px 0;
        border-bottom: 1px solid #e5e5e5;
        &-rankingName {
            padding-left: 8px;
        }
    }
}
.loading {
    text-align: center;
}
.not-ranking {
    width: 30px;
    height: 30px !important;
}
</style>
