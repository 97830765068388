import request from "./request";
//登录
export function login(data) {
    return request({
        url: "user/login",
        method: "post",
        data,
    });
}
// 退出登录
export function exitLogin(data) {
    return request({
        url: "user/logout",
        method: "post",
        data,
    });
}
//直播列表
export function liveList(params) {
    return request({
        url: "/live/list",
        method: "get",
        params: params,
    });
}

//分享登记二维码接口
export function qrcode(params) {
    return request({
        url: "/user/qrcode?uri=" + params.uri,
        method: "get",
    });
}
//个人中心接口
export function getuserinfo(params) {
    return request({
        url: "/user/info",
        method: "get",
        params: params,
    });
}

//首页轮播图
export function indexBanner(params) {
    return request({
        url: "/index/banner",
        method: "get",
        params: params,
    });
}

//直播详情接口
export function liveDetail(params) {
    return request({
        url: "/live/detail",
        method: "get",
        params: params,
    });
}

//直播答题答案提交接口
export function liveAnswer(data) {
    return request({
        url: "/live/answer",
        method: "PUT",
        data: data,
    });
}

//直播答题结果接口
export function liveResult(params) {
    return request({
        url: "/live/result",
        method: "get",
        params: params,
    });
}

//--------------------------------------------------------------------
//答题列表
export function answerList(params) {
    return request({
        url: "/answer/list",
        method: "get",
        params: params,
    });
}

// 答题提交接口
export function answerSubmit(data) {
    return request({
        url: "/answer/submit",
        method: "post",
        data,
    });
}
// 获取答题状态
export function answerResult(params) {
    return request({
        url: "/answer/result",
        method: "get",
        params,
    });
}
//答题详情
export function answerDetail(params) {
    return request({
        url: "/answer/detail",
        method: "get",
        params,
    });
}
// 答题记录
export function userAnswerList(params) {
    return request({
        url: "user/answerList",
        method: "get",
        params,
    });
}
// 答题记录详情
export function userAnswerInfo(params) {
    return request({
        url: "user/answerInfo",
        method: "get",
        params,
    });
}
// 积分列表
export function getScoreList(params) {
    return request({
        url: "user/answerInfo",
        method: "get",
        params,
    });
}
// 用户修改信息
export function editUserInfo(params) {
    return request({
        url: "user/editUserInfo",
        method: "put",
        params,
    });
}
// 用户积分记录
export function integralList(params) {
    return request({
        url: "user/integralList",
        method: "get",
        params,
    });
}
// 答题积分详情
export function answerIntegralInfo(params) {
    return request({
        url: "answer/integralInfo",
        method: "get",
        params,
    });
}
// 错题记录列表
export function userFailList(params) {
    return request({
        url: "user/failList",
        method: "get",
        params,
    });
}
// 错题详情
export function userFailInfo(params) {
    return request({
        url: "user/failInfo",
        method: "get",
        params,
    });
}
// 积分排名
export function integralTopList(params) {
    return request({
        url: "user/integralTopList",
        method: "get",
        params,
    });
}
// 企业列表
export function getCompanyList(params) {
    return request({
        url: "company/getCompanyList",
        method: "get",
        params,
    });
}
// 注册
export function register(data) {
    return request({
        url: "user/register",
        method: "post",
        data,
    });
}
// 首页配置信息
export function indexConfig(params) {
    return request({
        url: "index/config",
        method: "GET",
        params,
    });
}
// 承诺书
export function clockGetCommitmentInfo(params) {
    return request({
        url: "clock/getCommitmentInfo",
        method: "GET",
        params,
    });
}
// 提交打卡
export function clockSubmitClock(data) {
    return request({
        url: "clock/submitClock",
        method: "POST",
        data,
    });
}
// 今日打卡列表
export function clockGetTodayClockList(params) {
    return request({
        url: "clock/getTodayClockList",
        method: "get",
        params,
    });
}

// 打卡记录
export function getClockList(params) {
    return request({
        url: "clock/getClockList",
        method: "get",
        params,
    });
}
// 上传
export function uploadGetSign(params) {
    return request({
        url: "upload/getSign",
        method: "get",
        params,
    });
}
//首页jssdk授权
export function indexJssdk(params) {
    return request({
      url: "/index/jssdk",
      method: "get",
      params: params,
    });
  }
//   新闻置顶
export function getHeadNews(params) {
    return request({
      url: "news/getHeadNews",
      method: "get",
      params: params,
    });
  }
  //   新闻热点
export function getHotNews(params) {
    return request({
      url: "/news/getHotNews",
      method: "get",
      params: params,
    });
  }
  //   顶部新闻
export function getTopNews(params) {
    return request({
      url: "/news/getTopNews",
      method: "get",
      params: params,
    });
  }
  //   获取新闻详情
export function getNewsDetail(params) {
    return request({
      url: "/news/getNewsDetail",
      method: "get",
      params: params,
    });
  }