<template>
    <!-- 答题记录详情 -->
    <div>
        <headerBox bgColor="#fff">答题详情</headerBox>
        <div class="">
            <div>
                <van-swipe
                    class="my-swipe"
                    indicator-color="white"
                    :show-indicators="false"
                    ref="swiper"
                >
                    <van-swipe-item v-for="item in topicList" :key="item.id"
                        ><div class="swiper box">
                            <topic
                                way="view"
                                v-bind="item"
                                :step="2"
                                :total="topicList.length"
                                :listLength="topicList.length"
                                @changeTopic="changeTopic"
                            ></topic></div
                    ></van-swipe-item>
                </van-swipe>
            </div>
        </div>
    </div>
</template>

<script>
import topic from "@/components/topic.vue";
import { userAnswerInfo } from "@/util/api";
import headerBox from "@/components/headerBox";
import { pageMixin } from "@/util/pageMixin";

export default {
    mixins: [pageMixin],
    components: {
        topic,
        headerBox,
    },
    data() {
        return {
            topicList: [],
        };
    },
    created() {
        this.userAnswerInfo();
        this.indexJssdk();
    },
    methods: {
        async userAnswerInfo() {
            try {
                const { data, code } = await userAnswerInfo({
                    answer_id: this.$route.query.answer_id,
                });
                if (code == 0) {
                    this.topicList = data.data.list;
                }
            } catch (error) {
                console.log(error);
            }
        },
        changeTopic(state) {
            state === "up"
                ? this.$refs.swiper.prev()
                : this.$refs.swiper.next();
        },
    },
};
</script>

<style lang="scss" scoped>
.box {
    min-height: calc(100vh - 44px);
    background: #f8f8f8;
    padding: 15px;
    box-sizing: border-box;
    .swiper {
        min-height: 95vh;
        box-sizing: border-box;
    }
}
</style>
