<template>
    <!-- 题目封装组件 -->
    <div class="topic">
        <div class="title">
            {{ topicType }}
            <van-icon
                name="play"
                class="triangle triangle-left"
                v-if="listLength > 1"
            />
            <span class="title-num">{{ questions_no }}</span>
            <span>/</span>
            <span>{{ total }} </span>
            <van-icon v-if="listLength > 1" name="play" class="triangle" />
            <span class="title-text"> {{ topic_title }}</span>
        </div>
        <div class="topic-img" v-if="topic_img">
            <img :src="topic_img" alt="" />
        </div>
        <!-- 单选 -->
        <div v-if="topic_type == 1">
            <div
                class="topic-ceil"
                :class="[
                    active == index + 1 && step == 1 ? 'active' : '',
                    showRadioError(index + 1) ? 'error' : '',
                ]"
                v-for="(item, index) in topic_answer_options"
                :key="index"
                @click="select(index + 1)"
            >
                <span class="topic-ceil-option">
                    {{ optionObj[index + 1] }}
                </span>

                <span class="flex"> {{ item }}</span>

                <!-- 选项 -->
                <span
                    class="topic-ceil-correct"
                    v-if="index + 1 == active && step == 1"
                >
                    <img src="@/assets/select-option.png" alt="" />
                </span>
                <!-- 展示 -->
                <span
                    class="topic-ceil-correct"
                    v-if="
                        index + 1 == active &&
                        step != 1 &&
                        active == radioAnswer
                    "
                >
                    <img src="@/assets/select-option.png" alt="" />
                </span>
                <!-- 错误答案 -->
                <span class="topic-ceil-error" v-if="showRadioError(index + 1)">
                    <van-icon name="cross" />
                </span>
            </div>
        </div>
        <!-- 多选 -->
        <div v-if="topic_type == 2">
            <div
                class="topic-ceil"
                v-for="(item, index) in topic_answer_options"
                :key="index"
                :class="[
                    multipleActive.includes(index + 1) && step != 2
                        ? 'active'
                        : '',
                    showError(index + 1) ? 'error' : '',
                ]"
                @click="multipleSelect(index + 1)"
            >
                <span>
                    <span class="topic-ceil-option">
                        {{ optionObj[index + 1] }}
                    </span>

                    <span class="flex">{{ item }}</span>
                </span>
                <!-- 选项  选择 -->
                <span
                    class="topic-ceil-correct"
                    v-if="multipleActive.includes(index + 1) && step == 1"
                >
                    <img src="@/assets/select-option.png" alt="" />
                </span>
                <!-- 选项  展示 -->
                <span
                    class="topic-ceil-correct"
                    v-if="
                        multipleActive.includes(index + 1) &&
                        step != 1 &&
                        !showError(index + 1)
                    "
                >
                    <img src="@/assets/select-option.png" alt="" />
                </span>
                <!-- 错误答案 -->
                <span
                    class="topic-ceil-error"
                    v-if="step != 1 && showError(index + 1)"
                >
                    <van-icon name="cross" />
                </span>
            </div>
        </div>
        <!-- 正确答案 === 答题 -->
        <div class="correct-answer" v-if="way == 'operation' && step == 2">
            <div class="correct-answer-left">
                <span>
                    <img src="@/assets/selected.png" alt="" />
                </span>
                <span>正确答案</span>
                <div class="correct-answer-left-option">
                    <span v-for="(i, index) in topic_correct_answer" :key="i"
                        >{{ optionObj[i] }}
                        <span v-if="topic_correct_answer.length - 1 != index"
                            >、</span
                        ></span
                    >
                </div>
            </div>
            <div class="correct-answer-right">
                <img src="@/assets/success.png" alt="" />
            </div>
        </div>
        <!-- 正确答案 === 预览 -->
        <div class="correct-answer" v-if="way == 'view'">
            <div class="correct-answer-left">
                <span>
                    <img src="@/assets/selected.png" alt="" />
                </span>
                <span class="correct-answer-left-answer">正确答案</span>
                <span class="correct-answer-left-option">
                    <span v-for="(i, index) in topic_correct_answer" :key="i">
                        {{ optionObj[i] }}
                        <span v-if="topic_correct_answer.length - 1 != index"
                            >、</span
                        >
                    </span>
                </span>
            </div>
        </div>
        <div v-if="way == 'view' && listLength > 1" class="switch">
            <Btn class="next-btn" @click="changeTopic('up')">上一题</Btn>
            <Btn class="next-btn" @click="changeTopic('next')">下一题</Btn>
        </div>
    </div>
</template>

<script>
import { Toast } from "vant";
import { answerSubmit } from "@/util/api";
import Btn from "@/components/btn";
export default {
    components: {
        Btn,
    },
    props: {
        way: {
            default: "operation", // 可操作性
        },
        questions_no: {
            default: 1,
        },
        total: {
            default: 0,
        },
        topic_title: {
            default: "",
        },
        topic_img: {
            default: "",
        },
        topic_answer_options: {
            default: [],
        },
        topic_correct_answer: {
            default: [],
        },
        topic_type: {
            default: 1,
        },
        id: {
            default: 0,
        },
        step: {
            default: 1,
        },
        answer: {
            //已选择答案
            default: "",
        },
        loading: {
            default: false,
            type: Boolean,
        },
        listLength: {
            default: 0,
            type: Number,
        },
    },
    data() {
        return {
            active: "9999", // 单选选中
            multipleActive: [], // 多选选中
            showCorrect: true,
            pluralAnswer: [], // 多选正确答案
            radioAnswer: "", //单选正确答案
            optionObj: {
                1: "A",
                2: "B",
                3: "C",
                4: "D",
                5: "E",
                6: "F",
                7: "G",
                8: "H",
                9: "I",
                10: "J",
                11: "K",
                12: "L",
                13: "M",
                14: "N",
            },
            isTrue: false, //记录答案是否正确
        };
    },
    async mounted() {
        this.init();
        // to do 如果是查看 则赋值正确答案
        if (this.way == "view") {
            // 单选
            if (this.topic_type == 1) {
                this.active = this.answer;
            } else {
                this.multipleActive = this.answer
                    .split(",")
                    .map(item => Number(item));
            }
        }
    },
    computed: {
        topicType() {
            return this.topic_type == 1 ? "(单选题)" : "(多选题)";
        },
    },
    methods: {
        init() {
            // 单选
            if (this.topic_type == 1) {
                this.radioAnswer = this.topic_correct_answer[0];
            } else {
                // 多选
                this.pluralAnswer = this.topic_correct_answer;
            }
        },

        // 重置
        reset() {
            this.active = "9999"; // 单选选中
            this.multipleActive = []; // 多选选中
        },

        select(index) {
            if (this.way == "view" || this.step != 1) return;
            this.active = index;
        },
        multipleSelect(index) {
            if (this.way == "view" || this.step != 1) return;
            if (this.multipleActive.includes(index)) {
                this.multipleActive.splice(
                    this.multipleActive.indexOf(index),
                    1
                );
            } else {
                this.multipleActive.push(index);
            }
        },

        async submit() {
            // 单选检验
            if (this.topic_type == 1 && this.active == "9999")
                return Toast("请选择答案");
            // 多选检验
            if (this.topic_type == 2 && !this.multipleActive.length)
                return Toast("请选择答案");
            this.$emit("update:loading", true);
            //  提交答案
            this.isTrue =
                this.topic_type == 1
                    ? this.active == this.radioAnswer
                    : this.pluralAnswer.join() ==
                      this.multipleActive.sort().join();
            try {
                const { code } = await answerSubmit({
                    answer_id: this.$route.query.answer_id,
                    topic_id: this.id,
                    is_correct: this.isTrue ? 1 : 0,
                    answer:
                        this.topic_type == 1
                            ? this.active
                            : this.multipleActive.sort().join(),
                });
                if (code == 0) {
                    // 通知父组件进入第二步
                    Toast(this.isTrue ? "回答正确" : "回答错误");
                    this.$emit("inform", 2, this.isTrue);
                }
            } catch (error) {
                console.log(error);
            } finally {
                this.$emit("update:loading", false);
            }
        },
        // 多选显示错误
        showError(index) {
            // 取两数组交集
            let intersectionArr = this.multipleActive.filter(item =>
                this.pluralAnswer.includes(String(item))
            );
            if (this.step == 2) {
                if (intersectionArr.includes(index)) return false;
                return this.multipleActive.includes(index);
            }
            return false;
        },
        // 单选显示错误
        showRadioError(index) {
            if (this.step == 2) {
                if (this.active == this.radioAnswer && index == this.active)
                    return false;
                return this.active == index;
            }
            return false;
        },
        changeTopic(state) {
            this.$emit("changeTopic", state);
        },
    },
};
</script>

<style lang="scss" scoped>
.title {
    background: #fff;
    padding: 20px 10px;
    box-sizing: border-box;
    width: 100vw;
    transform: translateX(-15px);
    color: #cccccc;
    margin-bottom: 15px;
    &-num {
        font-weight: 700;
        color: #000;
    }
    &-text {
        color: #000;
    }
}
.topic {
    &-img {
        padding: 10px 0;
        > img {
            width: 100%;
        }
    }
    &-ceil {
        position: relative;
        background: #fff;
        padding: 15px;
        box-sizing: border-box;
        border-radius: 5px;
        box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.07);
        margin-bottom: 10px;
        border: 2px solid #fff;
        &-option {
            font-weight: 500;
            display: inline-block;
            width: 20px;
            color: #333333;
        }
    }
}
.active {
    border: 2px solid #ffe7a9;
}
.topic-ceil {
    display: flex;
    align-items: center;
}
.topic-ceil-correct {
    position: absolute;
    right: -2px;
    top: -2px;
    width: 20px;
    height: 16px;
    > img {
        width: 100%;
        height: 100%;
    }
    border-radius: 0px 4px 0px 13px;
}
.topic-ceil-error {
    color: var(--color-main);
    transform: translateX(6px);
}
.error {
    background: #f8e4e9;
    border: 2px solid #eeeeee !important;
    display: flex;
    border-radius: 5px;
    justify-content: space-between;
}
.correct-answer {
    padding: 15px;
    box-sizing: border-box;
    background: #fff;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    &-left {
        &-option {
            padding-top: 15px;
            color: var(--color-main);
        }
        &-answer {
            margin-right: 10px;
        }
        img {
            width: 15px;
            height: 15px;
            margin-right: 5px;
            transform: translateY(2px);
        }
    }
    &-right {
        width: 70px;
        height: 57px;
        > img {
            width: 100%;
            height: 100%;
        }
    }
}
.inline-block {
    display: inline-block;
    height: 100%;
}
.triangle {
    font-size: 14px;
    color: #000;
    &-left {
        transform: rotate(180deg);
    }
}
.switch {
    padding: 15px 0;
    display: flex;
    justify-content: space-between;
}
.next-btn {
    width: 165px;
}
</style>
