//微信分享
export function share(
    appId,
    timestamp,
    nonceStr,
    signature,
    mainTitle,
    subTitle,
    smallIcons,
    url
) {
    wx.config({
        debug: false, // 是否开启调试模式
        appId: appId, //appid
        timestamp: timestamp, // 时间戳
        nonceStr: nonceStr, // 随机字符串，只有这个是驼峰🎃
        signature: signature, // 签名
        jsApiList: ["onMenuShareTimeline", "onMenuShareAppMessage"], // 需要使用的JS接口列表
    });

    // 通过ready接口处理成功验证
    wx.ready(function () {
        // alert(JSON.stringify({
        // 	title: mainTitle, // 分享出去的title
        // 	desc: subTitle, // 描述信息
        // 	imgUrl: smallIcons, // 分享的小图标
        // 	link: url, // 分享的除去的url
        // 	type: 'link'}))
        const share = {
            title: mainTitle, // 分享出去的title
            desc: subTitle, // 描述信息
            imgUrl: smallIcons, // 分享的小图标
            link: url, // 分享的除去的url
            type: "link",
            success: function () {},
            cancel: function () {},
        };
        wx.onMenuShareAppMessage(share); // 微信好友
        wx.onMenuShareTimeline(share); // 朋友圈
        wx.error(function (res) {
            alert(res.errMsg);
        });
    });
}
