import axios from "axios";
import router from "../router";

const request = axios.create({
    //   baseURL: process.env.VUE_APP_BASE_URL,
    // baseURL: "http://cloud-company-test-api.ainifafa.com/",
    baseURL: "https://publicity-enterprise-api.jiyull.com/",
    //   baseURL: "/api",
    timeout: 10000, // Request timeout
    headers: {
        "Content-Type": "applsication/json",
        "Accept-Language": "zh-CN",
        "Access-Control-Allow-Origin": "*",
    },
});

// 拦截器 在请求之前拦截
request.interceptors.request.use(
    config => {
        const token = localStorage.getItem("UTOKEN");
        if (token) {
            config.headers["ApiToken"] = token;
        }
        return config;
    },
    error => {
        // Do something with request error
        Promise.reject(error);
    }
);
// 拦截器 在请求之后拦截
request.interceptors.response.use(
    response => {
        if (response.headers.authorization) {
            localStorage.setItem("UTOKEN", response.headers.authorization);
            response.data.UTOKEN = response.headers.authorization;
        }

        if ([-1, 106002, 100004, 200001].includes(response.data.code)) {
            router.push("/login");
            setTimeout(() => {
                localStorage.removeItem("UTOKEN");
            }, 800);
        }

        return response.data;
    },
    error => {
        if (!checkErrResponse(error)) return;
        return Promise.reject(error);
    }
);

function checkErrResponse(err) {
    return (
        err &&
        err.response &&
        err.response.data &&
        err.response.data.status_code
    );
}
export default request;
