/*
 * @Author: your name
 * @Date: 2022-04-18 13:45:15
 * @LastEditTime: 2022-04-25 10:25:00
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \cloud-live-admin\src\libs\ossRequest.js
 */

import axios from 'axios'

export function ossRequest (obj, file, onUploadProgress) {
  console.log(1)
  return new Promise((resolve, reject) => {
    // 创建一个空对象实例
    console.log(2)
    var formData = new FormData()
    // 获取当前时间戳加上获取的文件后缀 为文件名
    console.log(3)
    var filename = new Date().getTime() + getSuffix(file.name)
    console.log(4)

    formData.append('key', obj.dir + filename) // 存储在oss的文件路径
    formData.append('OSSAccessKeyId', obj.accessid) // accessKeyId
    formData.append('policy', obj.policy) // policy
    formData.append('Signature', obj.signature) // 签名
    formData.append('callback', obj.callback) // 签名
    // 如果是base64文件，那么直接把base64字符串转成blob对象进行上传就可以了
    formData.append('file', file)
    formData.append('secure', true)
    formData.append('success_action_status', 200) // 成功后返回的操作码
    //   var fileUrl = url + "/" + "ceshi/" + filename;
    console.log(obj.host)

    const config = {
      headers: { 'Content-Type': 'multipart/form-data', 'Content-Length-Range': 1677721600 }
    }

    return axios.post(obj.host, formData, config).then(function (result) {
      console.log(result)
      resolve(obj.host + '/' + obj.dir + filename)
    })
      .catch(function (error) {
        console.log(error)
        reject(error)
      })
  })
}

function getSuffix (fileName) {
  console.log(fileName)
  var pos = fileName.lastIndexOf('.')
  var suffix = ''
  if (pos !== -1) {
    suffix = fileName.substring(pos)
  }
  return suffix
}
