<template>
  <div>
    <headerBox bgColor="#fff" :isBack="false">登录</headerBox>
    <div class="box">
      <div class="top">
        <div class="logo">
          <img src="@/assets/logo@1x.png" alt="" />
        </div>
        <div class="form">
          <div class="max">
            <div class="label">手机号</div>
            <div class="input">
              <input
                maxlength="11"
                type="text"
                placeholder="请输入您的手机号"
                v-model="form.account"
                @input="accountShow = false"
              />
            </div>
            <div class="error" v-if="accountShow">
              {{ msg.accountShowMsg }}
            </div>
          </div>
          <div class="max">
            <div class="label">密码</div>
            <div class="input">
              <input
                type="password"
                placeholder="请输入密码"
                v-model="form.password"
                @input="passwordShow = false"
              />
              <div class="icon"></div>
            </div>
            <div class="error" v-if="passwordShow">
              {{ msg.passwordShowMsg }}
            </div>
          </div>
          <Btn class="btn" @click="submit" :loading="loading" :shadow="false"
            >登录</Btn
          >
          <div class="register">
            还没有账号？<span
              class="register-page"
              @click="
                $router.push({
                  path: '/register',
                  query: { ...$route.query },
                })
              "
              >去注册</span
            >
          </div>
        </div>
      </div>
      <div class="foot">
        <span class="foot-line"></span>
        <span class="foot-text">社会治理云宣传平台</span>
        <span class="foot-line foot-right"></span>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { login, getuserinfo } from "@/util/api";
import Btn from "@/components/btn.vue";
import headerBox from "@/components/headerBox";
import { pageMixin } from "@/util/pageMixin";
export default {
  mixins: [pageMixin],
  components: { Btn, headerBox },
  created() {
    this.indexJssdk();
  },
  data() {
    return {
      form: {
        account: "",
        password: "",
      },
      accountShow: false,
      passwordShow: false,
      msg: {
        accountShowMsg: "手机号不能为空",
        passwordShowMsg: "密码不能为空",
      },
      loading: false,
    };
  },
  methods: {
    // 登录
    async submit() {
      if (!this.form.account && !this.form.password) {
        this.accountShow = true;
        this.passwordShow = true;
      }
      if (!this.form.account) return (this.accountShow = true);
      if (!this.form.password) return (this.passwordShow = true);
      this.loading = true;
      try {
        const { code, data, msg } = await login(this.form);
        if (code == 0) {
          window.localStorage.setItem("UTOKEN", data.user_token);

          //   window.localStorage.setItem("userInfo", JSON.stringify(data));
          await this.getUserInfo();
          Toast("登录成功");

          if (this.$route.query.answer_type) {
            this.$router.push({
              path:
                this.$route.query.answer_type == 1 ? "/video" : "/answerDetail",
              query: { answer_id: this.$route.query.id },
            });
            return;
          }
          this.$router.push("/index");
        } else {
          Toast(msg);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async getUserInfo() {
      try {
        const { code, data } = await getuserinfo();
        if (code == 0) {
          window.localStorage.setItem("userInfo", JSON.stringify(data.data));
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  background: #fff;
  height: calc(100vh - 104px);
}
.logo {
  padding: 30px 88px 0 88px;
  > img {
    width: 100%;
  }
}
.form {
  padding: 40px 25px 0;
  .max {
    margin-bottom: 17px;
  }
  .label {
    font-size: 18px;
    color: #333333;
  }
  .input {
    padding: 12px 0;
    border-bottom: 1px solid #eaeaea;
  }
  .error {
    margin-top: 8px;
    color: #e62f37;
    font-size: 12px;
  }
}
.btn {
  margin-top: 30px;
  padding: 0;
}
.top {
  height: 100%;
  min-height: 500px;
}
.foot {
  padding-bottom: 40px;
  display: flex;
  text-align: center;
  font-size: 14px;
  color: #999;
  justify-content: center;
  align-items: center;
  &-line {
    width: 48px;
    height: 1px;
    background: linear-gradient(90deg, #ffffff 0%, #e5e5e5 99%);
  }
  &-right {
    background: linear-gradient(270deg, #ffffff 0%, #e5e5e5 99%);
  }
  &-text {
    padding: 0 15px;
  }
}
.register {
  padding: 30px 0;
  text-align: center;
  color: #ccc;
  &-page {
    color: var(--color-main);
  }
}
</style>
