var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"topic"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.topicType)+" "),(_vm.listLength > 1)?_c('van-icon',{staticClass:"triangle triangle-left",attrs:{"name":"play"}}):_vm._e(),_c('span',{staticClass:"title-num"},[_vm._v(_vm._s(_vm.questions_no))]),_c('span',[_vm._v("/")]),_c('span',[_vm._v(_vm._s(_vm.total)+" ")]),(_vm.listLength > 1)?_c('van-icon',{staticClass:"triangle",attrs:{"name":"play"}}):_vm._e(),_c('span',{staticClass:"title-text"},[_vm._v(" "+_vm._s(_vm.topic_title))])],1),(_vm.topic_img)?_c('div',{staticClass:"topic-img"},[_c('img',{attrs:{"src":_vm.topic_img,"alt":""}})]):_vm._e(),(_vm.topic_type == 1)?_c('div',_vm._l((_vm.topic_answer_options),function(item,index){return _c('div',{key:index,staticClass:"topic-ceil",class:[
                _vm.active == index + 1 && _vm.step == 1 ? 'active' : '',
                _vm.showRadioError(index + 1) ? 'error' : '' ],on:{"click":function($event){return _vm.select(index + 1)}}},[_c('span',{staticClass:"topic-ceil-option"},[_vm._v(" "+_vm._s(_vm.optionObj[index + 1])+" ")]),_c('span',{staticClass:"flex"},[_vm._v(" "+_vm._s(item))]),(index + 1 == _vm.active && _vm.step == 1)?_c('span',{staticClass:"topic-ceil-correct"},[_c('img',{attrs:{"src":require("@/assets/select-option.png"),"alt":""}})]):_vm._e(),(
                    index + 1 == _vm.active &&
                    _vm.step != 1 &&
                    _vm.active == _vm.radioAnswer
                )?_c('span',{staticClass:"topic-ceil-correct"},[_c('img',{attrs:{"src":require("@/assets/select-option.png"),"alt":""}})]):_vm._e(),(_vm.showRadioError(index + 1))?_c('span',{staticClass:"topic-ceil-error"},[_c('van-icon',{attrs:{"name":"cross"}})],1):_vm._e()])}),0):_vm._e(),(_vm.topic_type == 2)?_c('div',_vm._l((_vm.topic_answer_options),function(item,index){return _c('div',{key:index,staticClass:"topic-ceil",class:[
                _vm.multipleActive.includes(index + 1) && _vm.step != 2
                    ? 'active'
                    : '',
                _vm.showError(index + 1) ? 'error' : '' ],on:{"click":function($event){return _vm.multipleSelect(index + 1)}}},[_c('span',[_c('span',{staticClass:"topic-ceil-option"},[_vm._v(" "+_vm._s(_vm.optionObj[index + 1])+" ")]),_c('span',{staticClass:"flex"},[_vm._v(_vm._s(item))])]),(_vm.multipleActive.includes(index + 1) && _vm.step == 1)?_c('span',{staticClass:"topic-ceil-correct"},[_c('img',{attrs:{"src":require("@/assets/select-option.png"),"alt":""}})]):_vm._e(),(
                    _vm.multipleActive.includes(index + 1) &&
                    _vm.step != 1 &&
                    !_vm.showError(index + 1)
                )?_c('span',{staticClass:"topic-ceil-correct"},[_c('img',{attrs:{"src":require("@/assets/select-option.png"),"alt":""}})]):_vm._e(),(_vm.step != 1 && _vm.showError(index + 1))?_c('span',{staticClass:"topic-ceil-error"},[_c('van-icon',{attrs:{"name":"cross"}})],1):_vm._e()])}),0):_vm._e(),(_vm.way == 'operation' && _vm.step == 2)?_c('div',{staticClass:"correct-answer"},[_c('div',{staticClass:"correct-answer-left"},[_vm._m(0),_c('span',[_vm._v("正确答案")]),_c('div',{staticClass:"correct-answer-left-option"},_vm._l((_vm.topic_correct_answer),function(i,index){return _c('span',{key:i},[_vm._v(_vm._s(_vm.optionObj[i])+" "),(_vm.topic_correct_answer.length - 1 != index)?_c('span',[_vm._v("、")]):_vm._e()])}),0)]),_vm._m(1)]):_vm._e(),(_vm.way == 'view')?_c('div',{staticClass:"correct-answer"},[_c('div',{staticClass:"correct-answer-left"},[_vm._m(2),_c('span',{staticClass:"correct-answer-left-answer"},[_vm._v("正确答案")]),_c('span',{staticClass:"correct-answer-left-option"},_vm._l((_vm.topic_correct_answer),function(i,index){return _c('span',{key:i},[_vm._v(" "+_vm._s(_vm.optionObj[i])+" "),(_vm.topic_correct_answer.length - 1 != index)?_c('span',[_vm._v("、")]):_vm._e()])}),0)])]):_vm._e(),(_vm.way == 'view' && _vm.listLength > 1)?_c('div',{staticClass:"switch"},[_c('Btn',{staticClass:"next-btn",on:{"click":function($event){return _vm.changeTopic('up')}}},[_vm._v("上一题")]),_c('Btn',{staticClass:"next-btn",on:{"click":function($event){return _vm.changeTopic('next')}}},[_vm._v("下一题")])],1):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('img',{attrs:{"src":require("@/assets/selected.png"),"alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"correct-answer-right"},[_c('img',{attrs:{"src":require("@/assets/success.png"),"alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('img',{attrs:{"src":require("@/assets/selected.png"),"alt":""}})])}]

export { render, staticRenderFns }